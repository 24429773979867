export class LModule {
  // static allowableSymbols = [
  //     'F',
  //     'G',
  //     'H',
  //     'I',
  //     'r',
  //     'l',
  //     '+',
  //     '-',
  //     '[',
  //     ']',
  //     '@'
  // ]
  constructor(symbol, param = null, multiplier = null) {
    this.symbol = symbol;
    this.param = param;
    this.multiplier = multiplier;
    this.history = '';
  }
}

// Turn modules with no params into modules with params
//  by multiplying their multipliers by the input value x.
// Also add the ID of the rule and module used for resolution.
const resolveModule = (ruleModule, ruleIndex, modIndex, inputModule) => {
  let newMod = new LModule(ruleModule.symbol);
  if (ruleModule.multiplier) {
    newMod.param = inputModule.param * ruleModule.multiplier;
  }
  // Otherwise, use ruleModule's constant param
  else {
    newMod.param = ruleModule.param;
  }
  // Update history
  newMod.history = inputModule.history + `${ruleIndex}-${modIndex},`;
  return newMod;
};

//  Grow a parametric L-system
export const grow = (lsysConf, iters) => {
  let lstring = [lsysConf.axiom];
  for (let i = 0; i < iters; i++) {
    lstring = growOnce(lsysConf.rules, lstring);
  }
  return lstring;
};
const growOnce = (rules, lstring) => {
  let newLstring = [];
  for (let j = 0; j < lstring.length; j++) {
    const ruleIndex = rules.findIndex(
      (rule) => rule.inputVal.symbol === lstring[j].symbol
    );
    if (ruleIndex === -1) {
      newLstring.push(lstring[j]);
    } else {
      let result = rules[ruleIndex].outputVal;
      result = result.map((module, modIndex) => resolveModule(module, ruleIndex, modIndex, lstring[j]));
      newLstring.push(...result);
    }
  }
  return newLstring;
};

// Grow until a lengthLimit is reached or lstring length
//  stagnates for stagnationLimit iterations in a row.
// Returns lstring and the number of iterations that it completed before
//  termination condition was reached.
export const growUntil = (lsysConf, lengthLimit, stagnationLimit, iterationLimit) => {
  let lstring = [lsysConf.axiom];
  let iters = 0;
  let stagnationCounter = 0;
  while (lstring.length < lengthLimit && stagnationCounter < stagnationLimit && iters < iterationLimit) {
    const oldLength = lstring.length;
    let lengthLimitExceeded = false;
    [lstring, lengthLimitExceeded] = growOnceUntil(lsysConf.rules, lstring, lengthLimit)
    // checks if length limit exceeded during call to growOnceUntil
    //  (theoretically, we don't need the final return statement...)
    if (lengthLimitExceeded) {
      break;
    }
    if (lstring.length === oldLength) {
      stagnationCounter++;
    }
    else {
      stagnationCounter = 0;
    }
    iters++;
  }
  return [lstring, iters];
}
const growOnceUntil = (rules, lstring, lengthLimit) => {
  let newLstring = [];
  for (let j = 0; j < lstring.length; j++) {
    const ruleIndex = rules.findIndex(
      (rule) => rule.inputVal.symbol === lstring[j].symbol
    );
    if (ruleIndex === -1) {
      newLstring.push(lstring[j]);
    } else {
      let result = rules[ruleIndex].outputVal;
      result = result.map((module, modIndex) => resolveModule(module, ruleIndex, modIndex, lstring[j]));
      newLstring.push(...result);
    }
    if (newLstring.length > lengthLimit) {
      return [lstring, true];
    }
  }
  return [newLstring, false];
}

// Helper for converting to string
export const lmodulesToString = (lmodules) => {
  if (!Array.isArray(lmodules)) {
    return lmodules.symbol;
  }
  let stringResult = "";
  for (const lmodule of lmodules) {
    stringResult += lmodule.symbol;
  }
  return stringResult;
};

export const lmodulesToStringWithParams = (lmodules) => {
  let stringResult = "";
  for (const lmodule of lmodules) {
    if (lmodule.symbol === "F" ||
      lmodule.symbol === "G" ||
      lmodule.symbol === "H" ||
      lmodule.symbol === "I"
    ) {
        const multiplier = Math.round((lmodule.multiplier + Number.EPSILON) * 100) / 100;
        stringResult += `${lmodule.symbol}(${multiplier})`;
      }
    else if (lmodule.symbol === "r") {
      const param = Math.round((toDeg(lmodule.param) + Number.EPSILON) * 100) / 100;
      stringResult += `${lmodule.symbol}(${param})`
    }
    else {
      stringResult += lmodule.symbol;
    }
  }
  return stringResult;
}

// Helpers for creating modules in rules
const F = () => {
  return new LModule("F", null, 1);
};
const F_of_x_times = (multiplier) => {
  return new LModule("F", null, multiplier);
};
const G = () => {
  return new LModule("G", null, 1);
};
const G_of_x_times = (multiplier) => {
  return new LModule("G", null, multiplier);
};
const H = () => {
  return new LModule("H", null, 1);
};
const H_of_x_times = (multiplier) => {
  return new LModule("H", null, multiplier);
};
const r = (angle) => {
  return new LModule("r", angle * (Math.PI / 180));
};
const l = (angle) => {
  return new LModule("r", -angle * (Math.PI / 180));
};
const op = () => {
  return new LModule("[");
};
const cl = () => {
  return new LModule("]");
};

// Default lsysConf for adding a new L-ink
const defaultAnimConf = {
  bounceOn: false,
  swayOn: true,
  swayAngleAbs: 5,
  swayPeriod: 2,
  swaySynchronized: false,
  swaySmooth: true,
  bounceSmooth: true,
  bounceSynchronized: false,
  bouncePeriod: 3,
  bounceAmplitudeXMin: 1,
  bounceAmplitudeYMin: 1,
  bounceAmplitudeXMax: 1.1,
  bounceAmplitudeYMax: 1.1,
};

export const defaultLsysConf = {
  segmentLength: 2,
  iterations: 6,
  branchAngle: 25,
  branchThicknessScale: 0.8,
  branchLengthScale: 1.2,
  axiom: new LModule("F", 1),
  rules: [
    {
      id: 0,
      inputVal: F(),
      outputVal: [r(0), F(), op(), l(25.5), F(), cl(), r(0), F()]
    },
    {
      id: 1,
      inputVal: G(),
      outputVal: [r(0), G(), r(0), G()],
    },
    {
      id: 2,
      inputVal: H(),
      outputVal: [r(0), H(), r(0), H()],
    },
  ],
  stochasticHack: false,
  animated: false,
  animationConf: defaultAnimConf
};

const makeComparisonTestL_ink = (id, checked, branchLengthScale) => ({
    L_inkID: id,
    checked: checked,
    lsysConf: {
      segmentLength: 2,
      iterations: 6,
      branchAngle: 25.5,
      branchThicknessScale: 0.7,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), l(25.5), F_of_x_times(branchLengthScale), cl(), r(0), F(), op(), r(25.5), F_of_x_times(branchLengthScale), cl(), r(0), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), r(0), G()],
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
})

function shuffle(array) {
  let currentIndex = array.length;
  while (currentIndex !== 0) {
    let randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
}

// let comparisonBranchLengthScales = [0.001, 0.3, 0.6, 0.9, 1.2];
// // Shuffle to guard against ordering effects in user testing
// shuffle(comparisonBranchLengthScales);

// Instead of the above approach, I decided to shuffle a single time and
//   hard-code that shuffling so that I know which comparison case is
//   which to evaluate survey results
let comparisonBranchLengthScales = [0.3, 1.2, 0.001, 0.6, 0.9];

const comparisonL_inks = comparisonBranchLengthScales.map(
  (branchLengthScale, index) => makeComparisonTestL_ink(`comptest-version-${index}`, index===0, branchLengthScale)
);

function toRad(angleDeg) {
  return angleDeg * (Math.PI / 180);
}

function toDeg(angleRad) {
  return angleRad * (180 / Math.PI);
}

const trainingL_inks = [
  {
    L_inkID: "basic-plant",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 6,
      branchAngle: 25.5,
      branchThicknessScale: 0.7,
      branchLengthScale: 1.1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), l(25.5), F(), cl(), r(0), F(), op(), r(25.5), F(), cl(), r(0), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), r(0), G()],
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "lightning",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 10,
      branchAngle: 23,
      branchThicknessScale: 0.5,
      branchLengthScale: 1.6,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), G(), op(), r(23), F(), cl(), r(23), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), F(), op(), l(46), G(), cl(), l(46), F()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf,
    },
  },
  {
    L_inkID: "square-spiral",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.8,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), l(90), G(), cl(), r(0), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), F(), r(90), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "city-map",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.55,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), l(90), F(), cl(), r(0), G()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), op(), r(90), F(), cl(), r(0), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "molecular",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.45,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), r(toDeg(-1.47)), G_of_x_times(0.96), cl(), r(toDeg(0.54)), H()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), op(), r(toDeg(-0.94)), H(), cl(), r(toDeg(1.09)), F()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), op(), r(toDeg(0.66)), G_of_x_times(1.15), cl(), r(toDeg(-0.58)), F()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "spooky-branch",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.45,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), r(toDeg(-0.94)), F_of_x_times(0.74), cl(), r(toDeg(0.22)), G(), r(0), G()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), op(), r(0), F_of_x_times(2.28), cl(), r(0), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "wispy-hairy",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.3,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), r(toDeg(-0.16)), F_of_x_times(1.58), cl(), r(toDeg(-0.11)), G()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), op(), r(toDeg(0.04)), F_of_x_times(2.10), cl(), r(toDeg(0.11)), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "evil-spine",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.6,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), r(toDeg(2.68)), F_of_x_times(0.80), cl(), op(), r(toDeg(-2.68)), F_of_x_times(0.80), cl(), r(0), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), r(0), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "goopy",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 1.4,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), G(), op(), r(0), F_of_x_times(0.62), r(90), F_of_x_times(0.69), cl(), r(0), G()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), op(), r(0), F_of_x_times(0.75), cl(), r(0), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
  {
    L_inkID: "triangle-fractal",
    checked: false,
    lsysConf: {
      segmentLength: 2,
      iterations: 11,
      branchAngle: 90,
      branchThicknessScale: 0.5,
      branchLengthScale: 1,
      axiom: new LModule("F", 1),
      rules: [
        {
          id: 0,
          inputVal: F(),
          outputVal: [r(0), F(), op(), r(-60), F(), r(120), F(), cl(), r(0), F()]
        },
        {
          id: 1,
          inputVal: G(),
          outputVal: [r(0), G(), r(0), G()]
        },
        {
          id: 2,
          inputVal: H(),
          outputVal: [r(0), H(), r(0), H()],
        },
      ],
      stochasticHack: false,
      animated: false,
      animationConf: defaultAnimConf
    }
  },
];

// For the L-ink editing task: load a set of identical starting point L-inks
//   that the user should try to turn into the reference L-inks by
//   looking at the screenshot
// Starting point L-ink will be like basic plant with only one branch.
const makeExerciseTaskL_ink = (id) => ({
  L_inkID: id,
  checked: false,
  lsysConf: {
    segmentLength: 2,
    branchThicknessScale: 1,
    axiom: new LModule("F", 1),
    rules: [
      {
        id: 0,
        inputVal: F(),
        outputVal: [r(0), F(), op(), l(22), F(), cl(), r(0), F(), r(0), F()],
      },
      {
        id: 1,
        inputVal: G(),
        outputVal: [r(0), G(), r(0), G()],
      },
      {
        id: 2,
        inputVal: H(),
        outputVal: [r(0), H(), r(0), H()],
      },
    ],
    stochasticHack: false,
    animated: false,
    animationConf: defaultAnimConf
  }
});

let exerciseTaskIndices = [0, 1, 2, 3, 4];
const exerciseTaskL_inks = exerciseTaskIndices.map((index) => makeExerciseTaskL_ink(`exercise-version-${index}`));

// Default L-inks for new users
export const newUserL_inks = [
  // How can I turn the fern rules with Y and X into parametric L-sys?
  // Also, note that branchAngle and branchLengthScale will basically
  // be ignored. So will stochasticHack.
  ...comparisonL_inks,
  ...trainingL_inks,
  ...exerciseTaskL_inks
];
