import React from "react";

class RuleSymbol extends React.Component {

    constructor(props) {
        super(props);

        this.options = {
            opacity: 0.9,
            linecap: 'round',
            linejoin: 'round'
        };
        this.segLen = this.props.segLen;
        this.colorMap = this.props.colorMap;

        this.ruleInputSymbol = this.props.lsysConf.rules[this.props.ruleIndex].inputVal.symbol;
        this.options.color = this.colorMap[this.ruleInputSymbol];

        this.elementRef = React.createRef();
    }

    pointsToString(points) {
        let string = `${points[0][0]},${points[0][1]} ${points[1][0]},${points[1][1]}`;
        return string;
    }
    
    render() {
        const yBottom = 0.96 * this.props.height;
        const yTop = 0.96 * this.props.height - this.segLen;
        return (
            <div>
                <svg ref={this.elementRef}
                  style={{
                    width: this.props.width,
                    height: this.props.height,
                  }}
                  viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
                    <g>
                        <polyline
                            points={this.pointsToString([
                                [this.props.width / 2, yBottom],
                                [this.props.width / 2, yTop]
                            ])}
                            stroke={this.options.color}
                            strokeLinecap={this.options.linecap}
                            strokeLinejoin={this.options.linejoin}
                            strokeWidth={this.props.strokeWidth}
                            opacity={this.options.opacity}
                            >
                        </polyline>
                        <circle
                            // anchor point
                            cx={this.props.width / 2}
                            cy={yBottom}
                            r={0.32 * this.props.strokeWidth}
                            fill="rgb(165, 165, 165)"
                            >
                        </circle>
                        <line
                            // Dotted line extending up
                            x1={this.props.width / 2}
                            y1={yTop - 3}
                            x2={this.props.width / 2}
                            y2={0.19 * this.props.height}
                            stroke='rgb(165,165,165)'
                            strokeDasharray='8, 8'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={this.props.strokeWidth * 0.25}
                            opacity={this.options.opacity}
                            >
                        </line>
                    </g>
                </svg>
            </div>
        );
    }
}

export default RuleSymbol;