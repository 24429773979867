
import React from 'react'
import InkCard from './inkcard'

import './displaylist.css'

class DisplayList extends React.Component {

    render() {
        return(
            <div id="ink-list">
                {this.props.list.map(
                    (L_ink, index) =>
                        <InkCard
                            key={"l-ink-card-" + index + "_" + L_ink.L_inkID}
                            id={L_ink.L_inkID}
                            changeSelectedL_ink={this.props.changeSelectedL_ink}
                            index={index}
                            checked={L_ink.checked}
                            editDialogOpenIndex={this.props.editDialogOpenIndex}
                            openEditDialogAtIndex={this.props.openEditDialogAtIndex}
                            lsysConf={L_ink.lsysConf}
                            removeL_ink={this.props.removeL_ink}
                            handleLsysConfChange={this.props.handleLsysConfChange}
                            handleLsysRuleChange={this.props.handleLsysRuleChange}
                            handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
                            handleLsysAddModules={this.props.handleLsysAddModules}
                            handleLsysRemoveModules={this.props.handleLsysRemoveModules}
                            handleLsysConfReset={this.props.handleLsysConfReset}
                            changeL_inkID={this.props.changeL_inkID}
                            addL_inkSet={this.props.addL_inkSet}
                            setLstring={this.props.setLstring}
                            L_inkLengthLimit={this.props.L_inkLengthLimit}
                            L_inkStagnationLimit={this.props.L_inkStagnationLimit}
                            L_inkIterLimit={this.props.L_inkIterLimit}
                            L_inkOrientationFlipped={this.props.L_inkOrientationFlipped}>
                        </InkCard>
                )}
            </div>
        )
    }
}

export default DisplayList