import React from 'react'
import { Card } from '@material-ui/core';
import './minicolorpalette.css';
import { CustomColor } from '../sketchpage/colorpalette';

export class MiniColorPalette extends React.Component {
    constructor(props){
        super(props);

        this.width = 2;
        this.height = 2;

        // const colorsHex = Object.values(this.props.colorMap);
        // const customColors = [new CustomColor(), new CustomColor(), new CustomColor(), new CustomColor()];

        // for (let i = 0; i < colorsHex.length; i++) {
        //     customColors[i].setHex(colorsHex[i]);
        // }

        // this.state = {
        //     symbolArray: [
        //         [customColors[0], customColors[1]],
        //         [customColors[2], customColors[3]]
        //     ]
        // };

        this.dragging = false;
        this.cachedOffset = [0, 0];
        this.previousOffset = [0, 0];
    }

    colorClicked = (e, symbol) => {
        e.stopPropagation();
        this.props.handleChangeSymbol(symbol, this.props.moduleId);
    }

    render() {
        // Get colors from colormap
        // const colorsHex = Object.values(this.props.colorMap);
        // const customColors = [new CustomColor(), new CustomColor(), new CustomColor(), new CustomColor()];

        // for (let i = 0; i < colorsHex.length; i++) {
        //     customColors[i].setHex(colorsHex[i]);
        // }

        // const symbolColorArray = [
        //     [],
        //     []
        // ]
        let colorArray = [];
        for (const key in this.props.colorMap) {
            colorArray.push({symbol: key, color: this.props.colorMap[key]});
        }
        colorArray = [
            [colorArray[0], colorArray[1]],
            [colorArray[2], colorArray[3]]
        ];
        
        return (<Card style={this.props.style}>
            <div className='color-rows-container' onContextMenu={(e) => e.preventDefault()}>
                {colorArray.map((row, i) => {
                    return (<div className='color-row' key={`r-${i}`}>
                        {row.map((color, j) => {
                            let selected = color.symbol === this.props.currentSymbol;
                            return (<div 
                                className='color-icon'
                                style={selected ? {
                                    backgroundColor: color.color,
                                    border: '2px solid rgb(225 49 90)'
                                } : {backgroundColor: color.color}}
                                key={`c-${i}-${j}`}
                                onClick={(e) => this.colorClicked(e, color.symbol)}>
                            </div>)
                        })}
                    </div>)
                })}
            </div>
        </Card>);
    }
}