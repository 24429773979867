import React from "react";
import TextField from "@material-ui/core/TextField";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import FormGroup from "@material-ui/core/FormGroup";
import { Button, Slider } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RuleEditor from "./ruleeditor";
import RuleSymbol from "./rulesymbol";
import styled from "@material-ui/core";

import { transformStringToParams, paramsToTransformString } from "../util";
import Lsys2 from "../sketchpage/lsys2";
import SVG from "svg.js";

import "./inkeditor.css";
import { Background } from "react-flow-renderer";

class InkEditor extends React.Component {
  constructor(props) {
    super(props);

    this.previewWidth = 250;
    this.previewHeight = 350;
    this.previewSegLen = 5;

    this.ruleEditorWidth = 200;
    this.ruleEditorHeight = 200;
    this.ruleSymbolWidth = 30;
    this.ruleSymbolHeight = this.ruleEditorHeight;
    this.ruleStrokeWidth = 15;
    this.ruleSegLen = 55;

    // https://davidmathlogic.com/colorblind/#%23332288-%23117733-%2344AA99-%2388CCEE-%23DDCC77-%23CC6677-%23AA4499-%23882255
    this.colorMap = {
      'F': '#117733',
      'H': '#88CCEE',
      'G': '#DDCC77',
      'I': '#303030',
    };

    this.state = {
      L_inkID: this.props.id,
      previewIters: null,
      previewMaxIters: null
    };
  }

  componentDidMount() {
    this.draw = SVG.get("preview-group");
    // Create the entire lsystem preview
    const params = {
      width: 3,
      opacity: 1,
      fill: false
    }
    this.lsys = new Lsys2(
      this.draw,
      [], // coords
      {}, // layer
      params, // params
      {}, // logging
      this.props.lsysConf, // lsysConf
      null, // L-string (if null, should construct the string from scratch)
      false, // orientationFlipped
      true // isPreview
    );
    this.draw.clear();
    const maxIters = this.lsys.createPreview(this.previewWidth, this.previewHeight, this.previewSegLen, this.colorMap);
    // Update state with maxIters
    this.setState({
      previewIters: maxIters,
      previewMaxIters: maxIters,
    });
  }

  refreshPreview = (recalcMaxIters) => {
    // Recreate the entire lsystem preview
    this.lsys.setConf(this.props.lsysConf);
    this.draw.clear();
    if (recalcMaxIters) {
      const maxIters = this.lsys.createPreview(this.previewWidth, this.previewHeight, this.previewSegLen, this.colorMap, this.state.previewIters, true);
      this.setState({
        previewIters: maxIters,
        previewMaxIters: maxIters
      });
    }
    else {
      this.lsys.createPreview(this.previewWidth, this.previewHeight, this.previewSegLen, this.colorMap, this.state.previewIters); // adds the preview to this.draw
    }
  }

  // refreshPreviewBranchAngle = (branchAngle) => {
  //   // Just edit the params of the existing preview
  //   this.draw.each(function(i, children) {
  //       // For every branch and bend...
  //       if ((this.attr('class') === 'branch' || this.attr('class') === 'bend')) {
  //           // Extract params
  //           let [tx, ty, theta, sx, sy] = transformStringToParams(this.attr('transform'));
  //           // Change rotate transform
  //           let rotationAngle = this.attr('data-orient') * branchAngle;
  //           this.attr({
  //               transform: paramsToTransformString(tx, ty, rotationAngle, sx, sy)
  //           })
  //       }
  //   }, true);
  // }

  refreshPreviewBranchAngle = (ruleIndex, modIndex, branchAngle) => {
    // Just edit the params of the existing preview
    const changedRuleId = `${ruleIndex}-${modIndex}`;
    this.draw.each(function(i, children) {
      // For every bend...
        if (this.attr('class') === 'bend') {
          // Check if last rule element's history matches ruleIndex and modIndex of changed rule
          let history = this.attr('data-moduleHistory');
          history = history.substring(0, history.length - 1); // chop off final comma
          const lastId = history.substring(history.lastIndexOf(',') + 1);
          if (changedRuleId === lastId) {
            // Extract params
            let [tx, ty, theta, sx, sy] = transformStringToParams(this.attr('transform'));
            // Change rotate transform
            this.attr({
                transform: paramsToTransformString(tx, ty, branchAngle * (180 / Math.PI), sx, sy)
            })
          }
        }
    }, true);
  }

  // refreshPreviewBranchLengthScale = (branchLengthScale) => {
  //   const segLen = this.previewSegLen;
  //   this.draw.each(function(i, children) {
  //     if (this.attr('class') === 'branch' || this.attr('class') === 'bend') {
  //       // Recalculate coords of polyline and replot
  //       let newScale = Math.pow(branchLengthScale, this.attr('data-depth'));
  //       const poly = this.select('polyline').first();
  //       const points = poly.array().value;
  //       let unscaledPoints = []
  //       for (i = 0; i < points.length; i++) {
  //         let y = -i * segLen;
  //         unscaledPoints.push([0, y]);
  //       }
  //       const newPoints = unscaledPoints.map(([x, y]) => [x * newScale, y * newScale]);
  //       poly.plot(newPoints);
        
  //       // Recalculate transform of children
  //       // Branch point index represents how far along the parent branch this
  //       //    child originates. 0 means that only an origin was present when
  //       //    the branch was added
  //       const childs = this.select('g').first();
  //       childs.each(function(i, children) {
  //         const [tx, ty, theta, sx, sy] = transformStringToParams(this.attr('transform'));
  //         const unscaledTy = -segLen * this.attr('data-branchPointIndex');
  //         const newTy = newScale * unscaledTy;
  //         this.attr({
  //           transform: paramsToTransformString(tx, newTy, theta, sx, sy)
  //         })
  //       })
  //     }
  //   }, true)
  // }
  lookupModuleById = (id) => {
    let [ruleIndex, modIndex] = id.split('-').map((x) => Number(x));
    return this.props.lsysConf.rules[ruleIndex].outputVal[modIndex];
  }

  refreshPreviewBranchLengthScale = (ruleIndex, modIndex) => {
    const changedRuleId = `${ruleIndex}-${modIndex}`;
    const segLen = this.previewSegLen;
    const lookupModuleById = this.lookupModuleById;
    this.draw.each(function(i, children) {
      // For each polyline, check if its history contains the changed rule
      if (this.type === 'polyline') {
        if (this.array().value.length <= 1) {
          return;
        }
        let history = this.attr('data-moduleHistory');
        const containsChangedRule = history.includes(changedRuleId);
        if (containsChangedRule) {
          // Recalculate accumulated scale by multiplying
          //   through by all the scales contained in history
          history = history.split(',');
          history.pop(); // remove final empty string
          let accumulatedScale = 1;
          for (const ruleModId of history) {
            let mult = lookupModuleById(ruleModId).multiplier;
            accumulatedScale *= mult;
          }
          const points = this.array().value;
          // Start from the points without any scaling applied at all
          //  (base length)
          let unscaledPoints = [];
          for (i = 0; i < points.length; i++) {
            let y = -i * segLen;
            unscaledPoints.push([0, y]);
          }
          // Then scale by the new scale value
          const newPoints = unscaledPoints.map(([x, y]) => [x * accumulatedScale, y * accumulatedScale]);
          this.plot(newPoints);

          // Recalculate transform of children
          // Branch point index represents how far along the parent branch this
          //    child originates. 0 means that only an origin was present when
          //    the branch was added
          const parentGroup = this.parent();
          const childs = parentGroup.select('g').first();
          childs.each(function(i, children) {
            const [tx, ty, theta, sx, sy] = transformStringToParams(this.attr('transform'));
            const unscaledTy = -segLen * this.attr('data-branchPointIndex');
            const newTy = accumulatedScale * unscaledTy;
            this.attr({
              transform: paramsToTransformString(tx, newTy, theta, sx, sy)
            })
          })
        }
      }
      return;
    }, true)
  }

  refreshPreviewBranchThicknessScale(branchThicknessScale) {
    let base = this.draw.select('#prevBase').first();
    let basePoly = base.select('polyline').first();
    let baseThickness = basePoly.attr('stroke-width');
    this.draw.each(function(i, children) {
      if (this.type === 'polyline') { // TODO: fix for base branch which has no data-depth rn
        const thickness = baseThickness * Math.pow(branchThicknessScale, this.attr('data-depth'));
        this.attr({'stroke-width': thickness});
      }
    }, true)
  }

  refreshPreviewAnimationSwayAngle(swayAngle) {
    if (this.props.L_inkOrientationFlipped) {
      swayAngle = -swayAngle;
    }
    this.draw.each(function(i, children) {
      if (this.type === 'animateTransform' && this.node.getAttribute('type') === 'rotate') {
        this.node.setAttribute('values', `0; ${swayAngle}; 0; ${-swayAngle}; 0;`)
      }
    }, true);
  }

  refreshPreviewAnimationSwayPeriod(swayPeriod) {
    this.draw.each(function(i, children) {
      if (this.type === 'animateTransform' && this.node.getAttribute('type') === 'rotate') {
        this.node.setAttribute('dur', swayPeriod + "s");
      }
    }, true);
  }

  refreshPreviewAnimationBounceFactor(xMin, xMax, yMin, yMax) {
    this.draw.each(function(i, children) {
      if (this.type === 'animateTransform' && this.node.getAttribute('type') === 'scale') {
        this.node.setAttribute('values',
          `${xMin} ${yMin};
           ${xMax} ${yMax};
           ${xMin} ${yMin};`
          );
      }
    }, true);
  }

  refreshPreviewAnimationBouncePeriod(bouncePeriod) {
    this.draw.each(function(i, children) {
      if (this.type === 'animateTransform' && this.node.getAttribute('type') === 'scale') {
        this.node.setAttribute('dur', bouncePeriod + "s");
      }
    }, true);
  }

  getAnimationOptions = (animated) => {
    return (
      <div
        className={`animation-settings ${animated ? "expanded" : "collapsed"}`}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.lsysConf.animationConf.swayOn}
              onChange={(e) =>
                this.props.handleLsysConfChange(
                  {
                    animationConf: {
                      ...this.props.lsysConf.animationConf,
                      swayOn: !this.props.lsysConf.animationConf.swayOn,
                    }
                  },
                  this.props.index
                ).then(() => {
                  this.refreshPreview();
                })
              }
            />
          }
          label="Sway:"
        />
        <div className={`sway-settings ${this.props.lsysConf.animationConf.swayOn ? "" : "faded-text"}`}>
          <div className="slider-container">
            <div className="small-heading-padding">
              Sway Angle:{" "}
              <span className="bold-text">
                {this.props.lsysConf.animationConf.swayAngleAbs}&deg;
              </span>
            </div>
            <div className="filter-container-center">
              <Slider
                value={Number(this.props.lsysConf.animationConf.swayAngleAbs)}
                color="secondary"
                min={0.5}
                max={180}
                step={0.5}
                onChange={(e, newVal) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        swayAngleAbs: newVal,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreviewAnimationSwayAngle(newVal);
                  })
                }
              />
            </div>
          </div>
          <div className="slider-container">
            <div className="small-heading-padding">
              Sway Duration:{" "}
              <span className="bold-text">
                {this.props.lsysConf.animationConf.swayPeriod} seconds
              </span>
            </div>
            <div className="filter-container-center">
              <Slider
                value={Number(this.props.lsysConf.animationConf.swayPeriod)}
                color="secondary"
                min={0.5}
                max={10}
                step={0.5}
                onChange={(e, newVal) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        swayPeriod: newVal,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreviewAnimationSwayPeriod(newVal);
                  })
                }
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.lsysConf.animationConf.swaySmooth}
                onChange={(e) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        swaySmooth:
                          !this.props.lsysConf.animationConf.swaySmooth,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreview();
                  })
                }
              />
            }
            label="Smooth"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.lsysConf.animationConf.swaySynchronized}
                onChange={(e) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        swaySynchronized:
                          !this.props.lsysConf.animationConf.swaySynchronized,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreview();
                  })
                }
              />
            }
            label="Synchronize"
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.lsysConf.animationConf.bounceOn}
              onChange={(e) =>
                this.props.handleLsysConfChange(
                  {
                    animationConf: {
                      ...this.props.lsysConf.animationConf,
                      bounceOn: !this.props.lsysConf.animationConf.bounceOn,
                    }
                  },
                  this.props.index
                ).then(() => {
                  this.refreshPreview();
                })
              }
            />
          }
          label="Bounce:"
        />
        <div className={`bounce-settings ${this.props.lsysConf.animationConf.bounceOn ? "" : "faded-text"}`}>
          <div className="slider-container">
            <div className="small-heading-padding">
              Bounce Factor X:{" "}
              <span className="bold-text">
                {this.props.lsysConf.animationConf.bounceAmplitudeXMax}
              </span>
            </div>
            <div className="filter-container-center">
              <Slider
                value={Number(
                  this.props.lsysConf.animationConf.bounceAmplitudeXMax
                )}
                color="secondary"
                min={0}
                max={2}
                step={0.1}
                onChange={(e, newVal) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        bounceAmplitudeXMax: newVal,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreviewAnimationBounceFactor(
                        this.props.lsysConf.animationConf.bounceAmplitudeXMin,
                        newVal,
                        this.props.lsysConf.animationConf.bounceAmplitudeYMin,
                        this.props.lsysConf.animationConf.bounceAmplitudeYMax,
                      );
                  })
                }
              />
            </div>
          </div>
          <div className="slider-container">
            <div className="small-heading-padding">
              Bounce Factor Y:{" "}
              <span className="bold-text">
                {this.props.lsysConf.animationConf.bounceAmplitudeYMax}
              </span>
            </div>
            <div className="filter-container-center">
              <Slider
                value={Number(
                  this.props.lsysConf.animationConf.bounceAmplitudeYMax
                )}
                color="secondary"
                min={0}
                max={2}
                step={0.1}
                onChange={(e, newVal) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        bounceAmplitudeYMax: newVal,
                      },
                    },
                    this.props.index
                  ).then(() => {
                    this.refreshPreviewAnimationBounceFactor(
                      this.props.lsysConf.animationConf.bounceAmplitudeXMin,
                      this.props.lsysConf.animationConf.bounceAmplitudeXMax,
                      this.props.lsysConf.animationConf.bounceAmplitudeYMin,
                      newVal
                    );
                  })
                }
              />
            </div>
          </div>
          <div className="slider-container">
            <div className="small-heading-padding">
              Bounce Duration:{" "}
              <span className="bold-text">
                {this.props.lsysConf.animationConf.bouncePeriod} seconds
              </span>
            </div>
            <div className="filter-container-center">
              <Slider
                value={Number(this.props.lsysConf.animationConf.bouncePeriod)}
                color="secondary"
                min={0.5}
                max={10}
                step={0.5}
                onChange={(e, newVal) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        bouncePeriod: newVal,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreviewAnimationBouncePeriod(newVal);
                  })
                }
              />
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.lsysConf.animationConf.bounceSmooth}
                onChange={(e) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        bounceSmooth:
                          !this.props.lsysConf.animationConf.bounceSmooth,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreview();
                  })
                }
              />
            }
            label="Smooth"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.lsysConf.animationConf.bounceSynchronized}
                onChange={(e) =>
                  this.props.handleLsysConfChange(
                    {
                      animationConf: {
                        ...this.props.lsysConf.animationConf,
                        bounceSynchronized:
                          !this.props.lsysConf.animationConf
                            .bounceSynchronized,
                      },
                    },
                    this.props.index
                  ).then(() => {
                      this.refreshPreview();
                  })
                }
              />
            }
            label="Synchronize"
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="flex-col">
          <div>
            <span className="l-ink-name-label">Name:</span>
            <TextField
              onFocus={(event) => event.stopPropagation()}
              onClick={(event) => event.stopPropagation()}
              onChange={(event) => {
                this.setState({ L_inkID: event.target.value });
              }}
              color="primary"
              value={this.state.L_inkID}
              size="small"
            />
          </div>
        </div>

        <div id="l-ink-settings-box">
          <div className="local-florist-icon">
            <LocalFloristIcon style={{ height: 60, marginTop: 2 }} />
          </div>
          <span>L-Ink Settings</span>
        </div>

        <div className="flex-direc-row">
            {/* Uses a placeholder since l-ink-preview-box has position: fixed */}
            <div id="l-ink-preview-box-placeholder"></div>
            <div id="l-ink-preview-box-with-settings">
              <div id="l-ink-preview-box">
                <svg
                  className="rounded-svg-box"
                  style={{width: this.previewWidth, height: this.previewHeight}}
                  viewBox={`0 0 ${this.previewWidth} ${this.previewHeight}`}
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <text x="5" y="20" fill="#999" fontSize="smaller">
                      Preview
                  </text>
                  <g id="preview-group"></g>
                </svg>
              </div>
              <Tooltip title="Preview only--does not affect final ink style.">
                <div className="slider-container">
                  <div className="small-heading-padding">
                    Preview Growth Level:
                  </div>
                  <div className="filter-container-center">
                    <Slider
                      value={Number(this.state.previewIters)}
                      color="primary"
                      min={0}
                      max={this.state.previewMaxIters}
                      marks={true}
                      step={1}
                      onChange={(e, newVal) =>
                        this.setState({ previewIters: newVal }, () => {
                          this.refreshPreview()
                        })
                      }
                    />
                  </div>
                </div>
              </Tooltip>
            </div>

          <div></div>
          <div className="flex-col">
            <div className="pr-1">
              <div className="flex-col axiom-rules-container">
                <FormGroup>
                  <Tooltip title={
                    <React.Fragment>
                      <div className="rule-instructions">
                        <i>SHIFT+click</i> a <span className="bold-pink">point</span> to add a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>CMD+click</i> a <span className="bold-pink">point</span> to delete a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>Click</i> a <b>branch</b> to change its color.
                      </div>
                    </React.Fragment>
                  } placement="right" arrow>
                    <div className="lsystem-param">
                      <div className="rule-title">Rule 1:</div>
                      <div className="rule-box-container">
                        <div className="rule-visual-and-text">
                          <RuleSymbol
                            width={this.ruleSymbolWidth}
                            height={this.ruleSymbolHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={0}
                            index={this.props.index}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleSymbol>
                        </div>
                        <div className="arrow"
                          style={{height: this.ruleEditorHeight}}>
                          →
                        </div>
                        <div className="rule-visual-and-text">
                          <RuleEditor
                            width={this.ruleEditorWidth}
                            height={this.ruleEditorHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={0}
                            index={this.props.index}
                            handleLsysConfChange={this.props.handleLsysConfChange}
                            handleLsysRuleChange={this.props.handleLsysRuleChange}
                            handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
                            handleLsysAddModules={this.props.handleLsysAddModules}
                            handleLsysRemoveModules={this.props.handleLsysRemoveModules}
                            refreshPreview={this.refreshPreview}
                            refreshPreviewBranchAngle={this.refreshPreviewBranchAngle}
                            refreshPreviewBranchLengthScale={this.refreshPreviewBranchLengthScale}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleEditor>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip title={
                    <React.Fragment>
                      <div className="rule-instructions">
                        <i>SHIFT+click</i> a <span className="bold-pink">point</span> to add a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>CMD+click</i> a <span className="bold-pink">point</span> to delete a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>Click</i> a <b>branch</b> to change its color.
                      </div>
                    </React.Fragment>
                  } placement="right" arrow>
                    <div className="lsystem-param">
                      <div className="rule-title">Rule 2:</div>
                      <div className="rule-box-container">
                        <div className="rule-visual-and-text">
                          <RuleSymbol
                            width={this.ruleSymbolWidth}
                            height={this.ruleSymbolHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={1}
                            index={this.props.index}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleSymbol>
                        </div>
                        <div className="arrow"
                          style={{height: this.ruleEditorHeight}}>
                          →
                        </div>
                        <div className="rule-visual-and-text">
                          <RuleEditor
                            width={this.ruleEditorWidth}
                            height={this.ruleEditorHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={1}
                            index={this.props.index}
                            handleLsysConfChange={this.props.handleLsysConfChange}
                            handleLsysRuleChange={this.props.handleLsysRuleChange}
                            handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
                            handleLsysAddModules={this.props.handleLsysAddModules}
                            handleLsysRemoveModules={this.props.handleLsysRemoveModules}
                            refreshPreview={this.refreshPreview}
                            refreshPreviewBranchAngle={this.refreshPreviewBranchAngle}
                            refreshPreviewBranchLengthScale={this.refreshPreviewBranchLengthScale}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleEditor>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                  <Tooltip title={
                    <React.Fragment>
                      <div className="rule-instructions">
                        <i>SHIFT+click</i> a <span className="bold-pink">point</span> to add a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>CMD+click</i> a <span className="bold-pink">point</span> to delete a branch.
                      </div>
                      <div className="rule-instructions">
                        <i>Click</i> a <b>branch</b> to change its color.
                      </div>
                    </React.Fragment>
                  } placement="right" arrow sx={{bgcolor: 'blue'}}>
                    <div className="lsystem-param">
                      <div className="rule-title">Rule 3:</div>
                      <div className="rule-box-container">
                        <div className="rule-visual-and-text">
                          <RuleSymbol
                            width={this.ruleSymbolWidth}
                            height={this.ruleSymbolHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={2}
                            index={this.props.index}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleSymbol>
                        </div>
                        <div className="arrow"
                          style={{height: this.ruleEditorHeight}}>
                          →
                        </div>
                        <div className="rule-visual-and-text">
                          <RuleEditor
                            width={this.ruleEditorWidth}
                            height={this.ruleEditorHeight}
                            lsysConf={this.props.lsysConf}
                            ruleIndex={2}
                            index={this.props.index}
                            handleLsysConfChange={this.props.handleLsysConfChange}
                            handleLsysRuleChange={this.props.handleLsysRuleChange}
                            handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
                            handleLsysAddModules={this.props.handleLsysAddModules}
                            handleLsysRemoveModules={this.props.handleLsysRemoveModules}
                            refreshPreview={this.refreshPreview}
                            refreshPreviewBranchAngle={this.refreshPreviewBranchAngle}
                            refreshPreviewBranchLengthScale={this.refreshPreviewBranchLengthScale}
                            colorMap={this.colorMap}
                            strokeWidth={this.ruleStrokeWidth}
                            segLen={this.ruleSegLen}
                            >
                          </RuleEditor>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </FormGroup>

              </div>
            </div>
          </div>

          <div
            className={`l-ink-vertical-setting-spacer ${
              this.props.lsysConf.animated ? "expanded" : "collapsed"
            }`}
          ></div>

          <div className="flex-col">
            <div className="pr-1">
              <div className="flex-col">
                <FormGroup>
                  <div className="slider-container">
                    <div className="small-heading-padding">
                      Branch Thickness Scale:{" "}
                      <span className="bold-text">
                        {this.props.lsysConf.branchThicknessScale}
                      </span>
                    </div>
                    <div className="filter-container-center">
                      <Slider
                        value={Number(
                          this.props.lsysConf.branchThicknessScale
                        )}
                        color="secondary"
                        min={0}
                        max={2}
                        step={0.01}
                        onChange={(e, newVal) =>
                          this.props.handleLsysConfChange(
                            { branchThicknessScale: newVal },
                            this.props.index
                          ).then(() => {
                              this.refreshPreviewBranchThicknessScale(newVal)
                          })
                        }
                      />
                    </div>
                  </div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.props.lsysConf.animated}
                        onChange={(e) =>
                          this.props.handleLsysConfChange(
                            { animated: !this.props.lsysConf.animated },
                            this.props.index
                          ).then(() => {
                              this.refreshPreview();
                          })
                        }
                      />
                    }
                    label="Animation"
                  />
                  {this.getAnimationOptions(this.props.lsysConf.animated)}
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InkEditor;
