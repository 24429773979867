import React from "react";
import DraggableSegment from "./draggablesegment";
import { MiniColorPalette } from "./minicolorpalette";
import "./ruleeditor.css";

class RuleEditor extends React.Component {
    constructor(props) {
        super(props);

        this.options = {
            width: this.props.strokeWidth,
            opacity: 0.9,
            linecap: 'round',
            linejoin: 'round'
        };
        this.segLen = this.props.segLen; // to be same as ruleSymbol

        this.origin = [0.5 * this.props.width, 0.96 * this.props.height];

        this.branchThicknessScale = 0.6;

        this.fractionOfBoxFilled = 0.5;

        this.idCounter = 0;
        this.elementRef = React.createRef();

        // Store the locations of all the draggable segments in a dictionary by id
        this.segments = {};

        this.state = {
          selectedSegmentId: null
        };

        // Construct the rule SVG
        this.constructSVG();
    }

    // componentDidUpdate() {
    //   this.constructSVG();
    // }

    constructSVG() {
      // First build an intermediate JavaScript object with the structure of lsystem
      this.ruleObj = this.constructRuleObj();
      // Then transform this object into JSX recursively
      this.ruleSVG = this.constructSvgRecursively(this.ruleObj);
      // This strategy is used because it's hard to manipulate JSX stuff dynamically 
    }

    constructRuleObj() {
        let ruleObj = {
          type: 'g',
          children: []
        };
        const rule_lstring = this.props.lsysConf.rules[this.props.ruleIndex].outputVal;
        let base = {
          id: 'ruleBase',
          type: 'g',
          polyline: {
            type: 'polyline',
            id: 'ruleBase',
            fill: 'none',
            stroke: {...this.options},
            points: [this.origin]
          },
          children: [],
        }
        ruleObj.children.push(base);
    
        // Variables to track during growth
        let currBranch = base;
        let currPoints = base.polyline.points;
        // let currLengthFactor = 1;
        let currHeading = -Math.PI / 2;
        let stack = [];
    
        // // Bbox bounds
        // let bbox = {
        //   xmin: 0,
        //   xmax: 0,
        //   ymin: 0,
        //   ymax: 0
        // };
        
        for (let i = 0; i < rule_lstring.length; i++) {
          const module = rule_lstring[i];
          const symbol = module.symbol;
          const lastPoint = currPoints[currPoints.length - 1];
          let currPoly = currBranch.polyline;
          const currChildren = currBranch.children;
          switch (symbol) {
            case 'G':
            case 'F':
            case 'H':
            case 'I':
              const segColor = this.props.colorMap[symbol];
              // If current polyline has no defined color, define it
              if (currPoly.stroke.color === undefined) {
                currPoly.stroke.color = segColor;
              }
              // If the current polyline is not the right color, create a new one
              else if  (currPoly.stroke.color !== segColor) {
                let polyId = currPoly.id === 'ruleBase' ? 'ruleBase' : undefined;
                let child = {
                  type: 'g',
                  polyline: {
                    type: 'polyline',
                    symbol: symbol,
                    id: polyId,
                    'data-forward-module-index': i,
                    'data-heading': currPoly['data-heading'],
                    fill: 'none',
                    stroke: {
                      ...this.options,
                      color: segColor,
                      width: currPoly.stroke.width
                    },
                    points: [lastPoint]
                  },
                  children: []
                };
                currChildren.push(child);
                currBranch = child;
                currPoints = [lastPoint];
                currPoly = currBranch.polyline;
              }
              // Multiplier of the module determines displacement. Default to 1.
              let multiplier = 1;
              if (module.multiplier) {
                multiplier = module.multiplier;
              }

              // Calculate the new point
              let displacement = [
                Math.cos(currHeading) * this.segLen * multiplier, 
                Math.sin(currHeading) * this.segLen * multiplier
              ];
              // if (currBranch.id === 'ruleBase') {
              //   displacement = [0, -this.segLen * multiplier];
              // }
              let newPoint = [lastPoint[0] + displacement[0], lastPoint[1] + displacement[1]];
              // Add it to current points
              currPoints.push(newPoint)
              // Replot the current polyline
              currPoly.points = currPoints;
              currPoly.symbol = symbol;
              // Track bounding box...
              // Calculate the point in container space
              // Update bounds
              // bbox.xmin = Math.min(bbox.xmin, newPoint[0]);
              // bbox.xmax = Math.max(bbox.xmax, newPoint[0]);
              // bbox.ymin = Math.min(bbox.ymin, newPoint[1]);
              // bbox.ymax = Math.max(bbox.ymax, newPoint[1]);
              break;
            case 'l':
            case '+':
              let oldHeadingL = currHeading;
              currHeading -= module.param;
              let polyIdL = currPoly.id === 'ruleBase' ? 'ruleBase' : undefined;
              let childL = {
                type: 'g',
                polyline: {
                  type: 'polyline',
                  id: polyIdL,
                  'data-forward-module-index': i+1, // Since we want to reference the F that comes next
                  'data-heading': oldHeadingL,
                  fill: 'none',
                  stroke: {
                    ...this.options,
                    color: currPoly.stroke.color,
                    width: currPoly.stroke.width
                  },
                  points: [lastPoint]
                },
                children: []
              };
              currChildren.push(childL);
              currBranch = childL;
              currPoints = [lastPoint];
              break;
            case 'r':
            case '-':
              let oldHeadingR = currHeading;
              currHeading += module.param;
              let polyIdR = currPoly.id === 'ruleBase' ? 'ruleBase' : undefined;
              let childR = {
                type: 'g',
                polyline: {
                  type: 'polyline',
                  id: polyIdR,
                  'data-forward-module-index': i+1, // Since we want to reference the F that comes next
                  'data-heading': oldHeadingR,
                  fill: 'none',
                  stroke: {
                    ...this.options,
                    color: currPoly.stroke.color,
                    width: currPoly.stroke.width
                  },
                  points: [lastPoint]
                },
                children: []
              };
              currChildren.push(childR);
              currBranch = childR;
              currPoints = [lastPoint];
              
              break;
            case '[':
              stack.push([currBranch, currPoints, currHeading]);
              // currLengthFactor = currLengthFactor * this.props.lsysConf.branchLengthScale;
              let child = {
                type: 'g',
                polyline: {
                  type: 'polyline',
                  fill: 'none',
                  stroke: {
                    ...this.options,
                    width: this.branchThicknessScale * currPoly.stroke.width
                  },
                  points: [lastPoint]
                },
                children: []
              };
              currChildren.push(child);
              currBranch = child;
              currPoints = [lastPoint];
              break;
            case ']':
              [currBranch, currPoints, currHeading] = stack.pop();
              break;
            default:
              break;
          }
        }
        return ruleObj;
    }

    constructSvgRecursively(obj) {
      // If obj is a polyline, return transformed version
      if (obj.type === 'polyline') {
        if (obj.points.length <= 1) return;
        const anchorPoint = obj.points[0];
        const dragPoint = obj.points[obj.points.length-1];
        const id = obj['data-forward-module-index'];
        const heading = obj['data-heading'];
        this.segments[id] = {
          anchorPoint: anchorPoint,
          dragPoint: dragPoint,
          heading: heading,
          id: obj.id,
          symbol: obj.symbol
        };
        const selected = this.state.selectedSegmentId === id;
        return (
          <DraggableSegment
            id={id}
            anchorPoint={anchorPoint}
            dragPoint={dragPoint}
            strokeWidth={obj.stroke.width}
            handleControlPtDragged={this.handleControlPtDragged}
            handleControlPtShiftClicked={this.handleControlPtShiftClicked}
            handleControlPtMetaClicked={this.handleControlPtMetaClicked}
            handleControlPtReleased={this.handleControlPtReleased}
            handleSegmentClicked={this.handleSegmentClicked}
            color={obj.stroke.color}
            opacity={obj.stroke.opacity}
            selected={selected}>
          </DraggableSegment>
        );
      }
      // old code for rule-base
      // else if (obj.type === 'polyline') {
      //   return (
      //     <polyline
      //       points={obj.points}
      //       stroke={obj.stroke.color}
      //       strokeWidth={obj.stroke.width}
      //       strokeOpacity={obj.stroke.opacity}
      //       strokeLinecap={obj.stroke.linecap}
      //       strokeLinejoin={obj.stroke.linejoin}
      //       fill={obj.fill}
      //       >
      //     </polyline>
      //   )
      // }
      // If obj is a group, return transformed version and
      //   recursively call on children
      return (
        <g
          transform={obj.transform}>
          {obj.polyline && this.constructSvgRecursively(obj.polyline)}
          {obj.children.map((child) => this.constructSvgRecursively(child))}
        </g>
      )
    }

    generateUniqueId() {
      this.idCounter += 1;
      return this.idCounter.toString();
    }

    handleControlPtDragged = (id, mouseX, mouseY) => {
      const svgRect = this.elementRef.current.getBoundingClientRect();
      const currX = mouseX - svgRect.left;
      const currY = mouseY - svgRect.top;
      if (currX >= 0 && currX <= svgRect.width && currY >= 0 && currY <= svgRect.height) {
        // Calculate the angle and length scale needed to put point at currX, currY
        const segment = this.segments[id];
        const heading = (Math.PI / 2) + segment.heading;
        const anchorPt = segment.anchorPoint;
        const dx = currX - anchorPt[0];
        const dy = currY - anchorPt[1];
        const newBranchAngle = Math.atan2(dx, -dy) - heading;
        const segLenTransf = this.segLen;
        const newBranchLengthScale = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)) / segLenTransf;

        // L-inkindex, ruleIndex, moduleIndex, param, multiplier
        this.props.handleLsysRuleModuleChange(this.props.index, this.props.ruleIndex, id - 1, {param: newBranchAngle}).then(() => {
          this.props.refreshPreviewBranchAngle(this.props.ruleIndex, id - 1, newBranchAngle)
        });
        // Only allow changing segment length if not on base branch
        if (segment.id !== 'ruleBase') {
          this.props.handleLsysRuleModuleChange(this.props.index, this.props.ruleIndex, id, {multiplier: newBranchLengthScale}).then(() => {
            this.props.refreshPreviewBranchLengthScale(this.props.ruleIndex, id);
          });
        }
      }
      return;
    }

    handleControlPtShiftClicked = (id) => {
      this.props.handleLsysAddModules(this.props.index, this.props.ruleIndex, id)
      .then(() => {
        this.props.refreshPreview(true) // Since lstring changed, should recalc max iters
      });
    }

    handleControlPtMetaClicked = (id) => {
      this.props.handleLsysRemoveModules(this.props.index, this.props.ruleIndex, id)
      .then(() => {
        this.props.refreshPreview(true)
      })
      this.setState({
        selectedSegmentId: null
      })
    }

    handleControlPtReleased = () => {
      this.props.refreshPreview(false);
    }

    handleSegmentClicked = (id) => {
      this.setState(prevState => {
        const selectedId = id === prevState.selectedSegmentId ? null : id;
        return { selectedSegmentId: selectedId };
      });
    }

    handleClickOutside = () => {
      this.setState({
        selectedSegmentId: null
      })
    }
    
    handleChangeSymbol = (newSymbol, moduleId) => {
      // Change the symbols in the rules
      this.props.handleLsysRuleModuleChange(this.props.index, this.props.ruleIndex, moduleId, {symbol: newSymbol}).then(() => {
        this.props.refreshPreview(true)
      });
      this.setState({
        selectedSegmentId: null
      })
    }
    
    render() {
        // TODO: using a memoization helper instead of reconstructing SVG every render
        //    would be a great idea
        this.constructSVG();

        let style = {};
        if (this.state.selectedSegmentId) {
          const segment = this.segments[this.state.selectedSegmentId];
          const midPt = [
            0.5 * (segment.dragPoint[0] + segment.anchorPoint[0]),
            0.5 * (segment.dragPoint[1] + segment.anchorPoint[1]),
          ]
          style = {
            position: 'absolute',
            borderRadius: '0% 15% 15% 15%',
            left: midPt[0],
            top: midPt[1],
            padding: '1px',
            border: '1px solid darkgrey'
          }
        }

        return (
            <div className="rule-editor-box"
              onClick={this.handleClickOutside}>
                <svg ref={this.elementRef}
                  className="rounded-svg-box"
                  style={{width: this.props.width, height: this.props.height}}
                  viewBox={`0 0 ${this.props.width} ${this.props.height}`}>
                    {this.ruleSVG}
                </svg>
                {this.state.selectedSegmentId && (
                  <MiniColorPalette
                    handleChangeSymbol={this.handleChangeSymbol}
                    colorMap={this.props.colorMap}
                    style={style}
                    moduleId={this.state.selectedSegmentId}
                    currentSymbol={this.segments[this.state.selectedSegmentId].symbol}>
                  </MiniColorPalette>
                )}
            </div>
        );
    }
}

export default RuleEditor;