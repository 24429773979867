import React from "react";

class DraggableSegment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDragging: false,
            isHovered: false
        }
    }

    pointsToString(points) {
        let string = `${points[0][0]},${points[0][1]} ${points[1][0]},${points[1][1]}`;
        return string;
    }

    handleMouseDownControlPt = (e) => {
        if (e.shiftKey) {
            this.props.handleControlPtShiftClicked(this.props.id);
        }
        else if (e.metaKey) {
            this.props.handleControlPtMetaClicked(this.props.id);
        }
        else {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isDragging: true });
    
            document.addEventListener('mousemove', this.handleMouseMoveControlPt);
            document.addEventListener('mouseup', this.handleMouseUpControlPt);
        }
    }

    handleMouseMoveControlPt = (e) => {
        if (this.state.isDragging) {
            // const svgRect = this.props.containerRef.current.getBoundingClientRect();
            // const currX = e.clientX - svgRect.left;
            // const currY = e.clientY - svgRect.top;
            // if (currX >= 0 && currX <= svgRect.width && currY >= 0 && currY <= svgRect.height) {
            //     this.setState({
            //         dragPoint: [currX, currY]
            //     });
            // }
            this.props.handleControlPtDragged(this.props.id, e.clientX, e.clientY);
        }
    }

    handleMouseUpControlPt = (e) => {
        this.setState({ isDragging: false });
        document.removeEventListener('mousemove', this.handleMouseMoveControlPt);
        document.removeEventListener('mouseup', this.handleMouseUpControlPt);
        this.props.handleControlPtReleased();
    }

    handleMouseEnterControlPt = () => {
        this.setState({ isHovered: true });
    }

    handleMouseLeaveControlPt = () => {
        this.setState({ isHovered: false });
    }

    handleClickSegment = (e) => {
        e.stopPropagation();
        this.props.handleSegmentClicked(this.props.id);
    }
 
    render() {
        return (
            <g>
                <polyline
                    // actual polyline
                    points={this.pointsToString([
                        this.props.anchorPoint,
                        this.props.dragPoint
                    ])}
                    fill="none"
                    stroke={this.props.color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={this.props.strokeWidth}
                    opacity={this.props.opacity}
                    style={{cursor: 'pointer'}}
                    // style={{pointerEvents: "none"}}
                    onClick={this.handleClickSegment}>
                </polyline>
                <circle
                    // anchor point
                    cx={this.props.anchorPoint[0]}
                    cy={this.props.anchorPoint[1]}
                    r={0.32 * this.props.strokeWidth}
                    fill="rgb(165, 165, 165)"
                    style={{pointerEvents: "none"}}
                    >
                </circle>
                {this.state.isHovered && (
                    <circle
                        // halo around draggable point
                        cx={this.props.dragPoint[0]}
                        cy={this.props.dragPoint[1]}
                        r={1.7 * this.props.strokeWidth}
                        opacity={0.3}
                        fill='rgb(225, 49, 90)'
                        >
                    </circle>
                )}
                <circle
                    // draggable point
                    cx={this.props.dragPoint[0]}
                    cy={this.props.dragPoint[1]}
                    r={0.85 * this.props.strokeWidth}
                    fill='rgb(225, 49, 90)'
                    style={{cursor: this.state.isDragging ? 'grabbing' : 'grab'}}
                    onMouseDown={this.handleMouseDownControlPt}
                    onMouseEnter={this.handleMouseEnterControlPt}
                    onMouseLeave={this.handleMouseLeaveControlPt}
                    >
                </circle>
            </g>
        )
    }
}

export default DraggableSegment;