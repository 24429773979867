import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CallSplitIcon from "@material-ui/icons/CallSplit";

import "./inkcard.css";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InkEditor from "./inkeditor";
import {LModule, growUntil} from "../lUtil";

class InkCard extends React.Component {
  constructor(props) {
    super(props);

    // this.L_INK_LENGTH_LIMIT = 300000;
    // this.L_INK_STAGNATION_LIMIT = 10;
    // this.L_INK_ITER_LIMIT = 20;

    this.state = {
      L_inkID: this.props.id,
    };
    this.editorRef = React.createRef();
  }

  cardClicked() {
    console.log(this.props.lsysConf.rules)
    if (this.props.checked) {
      this.props.changeSelectedL_ink("-1");
    } else {
      this.props.changeSelectedL_ink(this.props.index.toString());
      // Compute lstring
      // let lstring = grow(this.props.lsysConf, this.props.lsysConf.iterations);
      let [lstring, iters] = growUntil(this.props.lsysConf, this.props.L_inkLengthLimit, this.props.L_inkStagnationLimit, this.props.L_inkIterLimit);
      // Set this L-ink's lstring as the active one
      this.props.setLstring(lstring);
    }
  }

  closeEditDialog() {
    this.props.changeL_inkID(this.props.index, this.editorRef.current.state.L_inkID);
    this.props.openEditDialogAtIndex(-1);
    // If this is the currently selected L-ink,
    //   regenerate the lstring
    if (this.props.checked) {
      let [lstring, iters] = growUntil(this.props.lsysConf, this.props.L_inkLengthLimit, this.props.L_inkStagnationLimit, this.props.L_inkIterLimit);
      this.props.setLstring(lstring);
    }

    // this.props.sendLog("closed_edit_filter_dialog")
    // if (this.props.listLength === 1) {
    //     this.props.changeSelectedFilter("0")
    // }
    // this.props.refreshFilter(this.props.index)
  }

  editClicked = (event) => {
    event.stopPropagation();
    this.props.openEditDialogAtIndex(this.props.index);
  };

  remixClicked = (event) => {
    event.stopPropagation();
    // Implementing differently from filter remix
    this.props.addL_inkSet({
      L_inkID: this.props.id + "-remix",
      lsysConf: this.props.lsysConf,
    });
  };

  removeClicked = (event) => {
    event.stopPropagation();
    this.props.openEditDialogAtIndex(-1);
    if (this.props.checked) {
      this.props.changeSelectedL_ink("");
    }
    this.props.removeL_ink(this.props.index);
  };

  render() {
    return (
      <div id="filter-card">
        <Card onClick={() => this.cardClicked()}>
          <CardContent
            className={
              this.props.checked
                ? "filter-card-prop selected-card"
                : "filter-card-prop unselected-card"
            }
          >
            <div>
              <div>{this.state.L_inkID}</div>
              <div className="flex-left pad-top">
                <div className="pad-sides pad-top">
                  <div className="filter-card-btn" onClick={this.editClicked}>
                    <IconButton
                      color="secondary"
                      onFocus={(event) => event.stopPropagation()}
                      aria-label="edit filter"
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                    <span className="pad-top-small">Edit</span>
                  </div>
                </div>
                <div className="pad-sides pad-top">
                  <div className="filter-card-btn" onClick={this.remixClicked}>
                    <IconButton
                      color="secondary"
                      onFocus={(event) => event.stopPropagation()}
                      aria-label="remix filter"
                      size="small"
                    >
                      <CallSplitIcon />
                    </IconButton>
                    <span className="pad-top-small">Remix</span>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Dialog
          maxWidth={false}
          open={this.props.editDialogOpenIndex === this.props.index}
          onClose={() => this.closeEditDialog()}
        >
          <DialogTitle>{"Edit L-Ink"}</DialogTitle>
          <DialogContent dividers>
            <InkEditor
              ref={this.editorRef}
              id={this.props.id}
              index={this.props.index}
              changeL_inkID={this.props.changeL_inkID}
              lsysConf={this.props.lsysConf}
              handleLsysConfChange={this.props.handleLsysConfChange}
              handleLsysRuleChange={this.props.handleLsysRuleChange}
              handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
              handleLsysAddModules={this.props.handleLsysAddModules}
              handleLsysRemoveModules={this.props.handleLsysRemoveModules}
              hangleLsysConfReset={this.props.handleLsysConfReset}
              L_inkOrientationFlipped={this.props.L_inkOrientationFlipped}
            />
          </DialogContent>
          <div className="pad-top-and-sides flex-row">
            <div className="flex-row">
              <div
                className="filter-card-btn delete-l-ink-btn"
                onClick={this.removeClicked}
              >
                <IconButton
                  color="inherit"
                  onFocus={(event) => event.stopPropagation()}
                  aria-label="delete L-ink"
                  size="small"
                >
                  <DeleteForeverIcon />
                </IconButton>
                <span className="pad-top-small">Delete L-Ink</span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default InkCard;
