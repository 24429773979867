import _ from "lodash";
import "svg.filter.js";
import "../stabilizers/path-data-polyfill.js";
import { getCoordScaleFactor, getNumSigFigs } from "../util";
import { pathDataToPolys } from "svg-path-to-polygons";
import { transformStringToParams, paramsToTransformString } from "../util";
import simplify from "simplify-js";
import { LModule, grow, growUntil, lmodulesToString } from "../lUtil.js";
import SVG from "svg.js";

let getVec = (a, b) => [b[0] - a[0], b[1] - a[1]];
let rotVec = (v, theta) => [
  v[0] * Math.cos(theta) - v[1] * Math.sin(theta),
  v[0] * Math.sin(theta) + v[1] * Math.cos(theta),
];
let rotVec90 = (v) => [-v[1], v[0]];
let rotVec180 = (v) => [-v[0], -v[1]];
let scaleVec = (v, s) => [v[0] * s, v[1] * s];
let addVec = (a, b) => [a[0] + b[0], a[1] + b[1]];
let getVecLen = (v) => Math.sqrt(v[0] ** 2 + v[1] ** 2);
let normVec = (v) => {
  let length = Math.sqrt(v[0] ** 2 + v[1] ** 2);
  return [v[0] / length, v[1] / length]; // care, divide by zero
};
let calcOrthos = (direction, width) => {
  let normalizedOrthogonal = normVec(rotVec90(direction));
  let orthoCCW = scaleVec(normalizedOrthogonal, width);
  let orthoCW = rotVec180(orthoCCW);

  return [orthoCCW, orthoCW];
};

/** Single stroke of a sketch. SVG implementation. */
export default class Lsys2 {
  /**
   * Refactored so the constructor is cleaner
   * @param {object} draw - the svg draw object used to render this path
   * @param {Array} coords - the list of coordinates that define the path
   * @param {object} layer - the layer the stroke is contained in
   * @param {object} params - parameters that characterize the stroke (color, width, filterID, opacity, fill, filterIsVisible, variableWidth, isImported, strokeNode, scale)
   * @param {object} logging - used for logging user interactions (status, created, idCreator, idStroke, idMovedFrom, timeStart, timeEnd, rendered, erased)
   */

  // color, width, initialCoords, draw, idCreator, idStroke, status, idMovedFrom,
  //  created, timeStart, timeEnd, filterID, opacity, fill, filterIsVisible, variableWidth = true, layer
  constructor(draw, coords, layer, params, logging, lsysConf, lstring=null, orientationFlipped=false, isPreview=false, strokeNode=null) {
    this.draw = draw;
    this.coords = coords; // Flattened array of points, stored as [x1, y1, x2, y2, ...]
    this.layer = layer;
    this.params = params;
    this.logging = logging;
    this.strokeNode = strokeNode;

    this.svgPath = null;

    this.logging.rendered = true;
    this.logging.erased = false;
    this.movedFrom = null;

    this.options = {
      width: this.params.width,
      color: this.params.color,
      opacity: this.params.opacity,
      linecap: "round",
      linejoin: "round",
      fillopacity: this.params.opacity,
    };

    this.startTime = Date.now();

    // For lsystem preview
    this.PREVIEW_LENGTH_LIMIT = 5000; 
    this.PREVIEW_STAGNATION_LIMIT = 5;
    this.PREVIEW_ITER_LIMIT = 20;

    this.lsysConf = lsysConf;

    // If imported, set this.svgPath with strokeNode
    if (this.params.isImported) {
      let attributes = {};
      for (let attr of this.strokeNode.attributes) {
        attributes[attr.name] = attr.value;
      }
      this.svgPath = this.draw.group().attr(attributes);
      this.svgPath.svg(this.strokeNode.innerHTML);
      
      this.params.fill = this.params.strokeNode.getAttribute("fill") ? this.params.strokeNode.getAttribute("fill") : "none"
      this.params.fillOpacity = this.params.strokeNode.getAttribute("fill-opacity")
      this.params.color = this.params.strokeNode.getAttribute("stroke") ? this.params.strokeNode.getAttribute("stroke") : "black"
      this.params.width = this.params.strokeNode.getAttribute("stroke-width")
      this.params.strokeOpacity = this.params.strokeNode.getAttribute("opacity") ? this.params.strokeNode.getAttribute("opacity") : "1"
      this.params.filterResCorrection = 1; // not sure what this is
      
      this.params.opacity = this.params.fill !== "none" ? this.params.strokeOpacity : this.params.fillOpacity;
    }

    if (this.params.isImported) {
      return;
    }

    // Animation variables
    this.animationConf = this.lsysConf.animationConf;

    // Done setting up everything for preview
    if (isPreview) {
      return;
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // svgPath is a group which will contain a polyline and a group of children
    this.svgPath = this.draw.group().attr({
      id: 'base'
    });
    this.svgPath.polyline().fill("none").stroke(this.options);
    this.svgPath.group();

    // L-system variables ///////////////////////////////////////////////////////////////
    this.orientationFlipped = orientationFlipped;

    this.branchAngleRadians = this.lsysConf.branchAngle * (Math.PI / 180);

    this.lstring = lstring;
    
    this.segments = [];
    this.activeBranches = [
      {
        svg: this.svgPath,
        heading: 0,
        points: [],
        lstringPtr: 0,
        lengthFactor: 1,
        id: "base",
      },
    ]; // stack

    /////////////////////////////////////////////////////////////////////////////////////
    this.params.filterIsVisible =
      this.params.filterIsVisible === undefined
        ? true
        : this.params.filterIsVisible;
    if (
      this.params.filterID !== undefined &&
      this.params.filterID !== "empty" &&
      this.params.filterIsVisible
    ) {
      this.svgPath.attr("filter", "url(#" + this.params.filterID + ")");
    }

    if (this.params.isPath) this.calculatePathCoords();
    if (!this.params.stops) {
      this.params.stops = [];
    }
    this.hasGradient = false;
    this.gradientCollection = null;
    this.orderIndex = -1;

    if (this.params.potraced === undefined) this.params.potraced = false;
    if (this.params.isPath === undefined) this.params.isPath = false;
    if (this.params.pathRep === undefined) this.params.pathRep = "";

    this.svgPath.transform({
      a: 1 / this.params.filterResCorrection,
      b: 0,
      c: 0,
      d: 1 / this.params.filterResCorrection,
      e: 0,
      f: 0,
    });
  }

  setConf(newConf) {
    this.lsysConf = newConf;
    this.animationConf = newConf.animationConf;
  }

  createSwayAttrs(period, angle, begin, smooth) {
    let calcMode = smooth ? "spline" : "linear";
    return {
      attributeName: "transform",
      attributeType: "XML",
      type: "rotate",
      dur: period + "s",
      repeatCount: "indefinite",
      // values: `${-angle}; ${angle}; ${-angle};`,
      // keyTimes: `0; 0.5; 1`,
      // keySplines: "0.42 0 0.58 1; 0.42 0 0.58 1",
      values: `0; ${angle}; 0; ${-angle}; 0;`,
      keyTimes: `0; 0.25; 0.5; 0.75; 1`,
      keySplines: "0 0 0.58 1; 0.42 0 1 1; 0 0 0.58 1; 0.42 0 1 1",
      calcMode: calcMode,
      begin: begin,
      additive: "sum"
    };
  }

  createBounceAttrs(period, xMin, xMax, yMin, yMax, begin, smooth) {
    let calcMode = smooth ? "spline" : "linear";
    return {
      attributeName: "transform",
      attributeType: "XML",
      type: "scale",
      dur: period + "s",
      repeatCount: "indefinite",
      values: `${xMin} ${yMin};
               ${xMax} ${yMax};
               ${xMin} ${yMin};`,
      keyTimes: "0; 0.5; 1",
      keySplines: "0.42 0 0.58 1; 0.42 0 0.58 1",
      calcMode: calcMode,
      begin: begin,
      additive: "sum"
    }
  }

  createPreview(width, height, segLen, colorMap, iters, recalcMaxIters=false) {
    // If the max iters limit is unknown, grow to the limit and save it.
    // Otherwise, use the minimum of maxIters and iters
    let preview_lstring;
    if (!this.previewMaxIters || recalcMaxIters) {
      [preview_lstring, this.previewMaxIters] = growUntil(this.lsysConf,
        this.PREVIEW_LENGTH_LIMIT,
        this.PREVIEW_STAGNATION_LIMIT,
        this.PREVIEW_ITER_LIMIT
      );
    }
    else {
      const growthIters = Math.min(iters, this.previewMaxIters);
      preview_lstring = grow(this.lsysConf, growthIters);
    }

    let base = this.draw.group().attr({
      id: 'prevBase',
      'data-depth': 0
    });
    base.polyline().attr('data-depth', 0).fill("none").stroke(this.options);;
    base.group();

    // Timer for simulating desynced animations
    let fakeStrokeTimer = 0;
    const deltaT = 0.004;

    // Variables to track during growth
    let currBranch = base;
    let currPoints = [[0, 0]];
    // let currLengthFactor = 1;
    let stack = [];

    // Bbox bounds
    let bbox = {
      xmin: 0,
      xmax: 0,
      ymin: 0,
      ymax: 0
    };
    
    for (const module of preview_lstring) {
      const symbol = module.symbol;
      const param = module.param;
      const moduleHistory = module.history;
      let lastPoint = currPoints[currPoints.length - 1];
      let currPoly = currBranch.select("polyline").first();
      const currChildren = currBranch.select("g").first();
      switch (symbol) {
        case 'G':
        case 'F':
        case 'H':
        case 'I':
          fakeStrokeTimer += deltaT;
          const segColor = colorMap[symbol];
          // If the color we should be drawing is different from the color of
          //   the current polyline...
          if (segColor !== currPoly.attr('stroke')) {
            // Create a new child polyline
            let child = currChildren.group().attr({
              id: currBranch.attr('id'),
              class: 'continuation',
              transform: paramsToTransformString(lastPoint[0], lastPoint[1], 0, 1, 1),
              'data-branchPointIndex': currPoints.length - 1,
              'data-orient': 0,
              'data-depth': currBranch.attr('data-depth')
            });
            let currWidth = currPoly.attr('stroke-width');
            child.polyline().attr('data-depth', currBranch.attr('data-depth')).fill("none")
              .stroke({
                ...this.options,
                color: segColor,
                width: currWidth,
              });
            child.group();
            currBranch = child;
            currPoints = [[0, 0]];
            currPoly = currBranch.select('polyline').first();
            lastPoint = currPoints[currPoints.length - 1];
          }
          // Calculate the new point
          let displacement = [0, -segLen * param];
          let newPoint = addVec(lastPoint, displacement);
          // Add it to current points
          currPoints.push(newPoint);
          // Replot the current polyline
          currPoly.plot(currPoints);
          // Set history
          currPoly.attr({
            'data-moduleHistory': moduleHistory
          });
          // Track bounding box...
          // Calculate the point in container space
          let ctm = currPoly.node.getCTM();
          let newPointDOM = new DOMPointReadOnly(newPoint[0], newPoint[1]);
          let newPointT = newPointDOM.matrixTransform(ctm);
          // Update bounds
          bbox.xmin = Math.min(bbox.xmin, newPointT.x);
          bbox.xmax = Math.max(bbox.xmax, newPointT.x);
          bbox.ymin = Math.min(bbox.ymin, newPointT.y);
          bbox.ymax = Math.max(bbox.ymax, newPointT.y);
          break;
        case 'l':
        case '+':
          if (currBranch.attr('id') !== 'prevBase') {
            const [tx, ty, theta, sx, sy] = transformStringToParams(currBranch.attr('transform')); // TODO: don't think we need this
            // Create a new child and give it a rotate transform
            let child = currChildren.group().attr({
              class: 'bend',
              transform: paramsToTransformString(lastPoint[0], lastPoint[1], -param * (180 / Math.PI), sx, sy),
              'data-branchPointIndex': currPoints.length - 1,
              'data-orient': 0,
              'data-depth': currBranch.attr('data-depth')
            });
            let currWidth = currPoly.attr('stroke-width');
            child.polyline().attr('data-depth', currBranch.attr('data-depth')).fill("none")
              .stroke({
                ...this.options,
                width: currWidth,
              });
            child.group();
            currBranch = child;
            currPoints = [[0, 0]];
            // }
            // Assign current branch as an "l" branch
            // currBranch.attr({
            //   'data-orient': currBranch.attr('data-orient') - 1
            // });
            currBranch.attr({
              'data-moduleHistory': moduleHistory
            });
          }
          break;
        case 'r':
        case '-':
          if (currBranch.attr('id') !== 'prevBase') {
            const [tx, ty, theta, sx, sy] = transformStringToParams(currBranch.attr('transform'));
            // Create a new child and give it a rotate transform
            let child = currChildren.group().attr({
              class: 'bend',
              transform: paramsToTransformString(lastPoint[0], lastPoint[1], param * (180 / Math.PI), sx, sy),
              'data-branchPointIndex': currPoints.length - 1,
              'data-orient': 0,
              'data-depth': currBranch.attr('data-depth')
            });
            let currWidth = currPoly.attr('stroke-width');
            child.polyline().attr('data-depth', currBranch.attr('data-depth')).fill("none")
              .stroke({
                ...this.options,
                width: currWidth,
              });
            child.group();
            currBranch = child;
            currPoints = [[0, 0]];
            // }
            // Assign current branch as an "r" branch
            // currBranch.attr({
            //   'data-orient': currBranch.attr('data-orient') + 1
            // });
            currBranch.attr({
              'data-moduleHistory': moduleHistory
            });
          }
          break;
        case '[':
          stack.push([currBranch, currPoints]);
          let child = currChildren.group().attr({
            class: 'branch',
            transform: `translate(${lastPoint[0]}, ${lastPoint[1]})`,
            'data-branchPointIndex': currPoints.length - 1,
            'data-orient': 0,
            'data-depth': currBranch.attr('data-depth') + 1
          });
          let currWidth = currPoly.attr('stroke-width');
          // currLengthFactor = currLengthFactor * this.lsysConf.branchLengthScale;
          child.polyline().attr('data-depth', currBranch.attr('data-depth')).fill("none")
            .stroke({
              ...this.options,
              width: this.lsysConf.branchThicknessScale * currWidth,
            });
          child.group();
          currBranch = child;
          currPoints = [[0, 0]];
          currBranch.attr({
            'data-moduleHistory': ''
          });
          if (this.lsysConf.animated) {
            // SWAY ANIMATION
            let swayAngle = this.animationConf.swayAngleAbs;
            if (this.orientationFlipped) {
              swayAngle = -this.animationConf.swayAngleAbs;
            }
            let swayBegin = (fakeStrokeTimer % this.animationConf.bouncePeriod) + "s"
            if (this.animationConf.swaySynchronized) {
              swayBegin = "0s";
            }
            let swayAttrs = this.createSwayAttrs(
              this.animationConf.swayPeriod,
              swayAngle,
              swayBegin,
              this.animationConf.swaySmooth
            );

            // BOUNCE ANIMATION
            let bounceBegin = (fakeStrokeTimer % this.animationConf.bouncePeriod) + "s"
            if (this.animationConf.bounceSynchronized) {
              bounceBegin = "0s";
            }
            let bounceAttrs = this.createBounceAttrs(
              this.animationConf.bouncePeriod,
              this.animationConf.bounceAmplitudeXMin,
              this.animationConf.bounceAmplitudeXMax,
              this.animationConf.bounceAmplitudeYMin,
              this.animationConf.bounceAmplitudeYMax,
              bounceBegin,
              this.animationConf.bounceSmooth
            );

            // ADD ANIMATIONS
            if (this.animationConf.bounceOn) {
              // child.element('animateTransform').attr(bounceAttrs);
              const animateTransform = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
              for (const [key, value] of Object.entries(bounceAttrs)) {
                animateTransform.setAttribute(key, value);
              }
              child.node.appendChild(animateTransform);
            }
            if (this.animationConf.swayOn) {
              // child.element('animateTransform').attr(swayAttrs);
              const animateTransform = document.createElementNS('http://www.w3.org/2000/svg', 'animateTransform');
              for (const [key, value] of Object.entries(swayAttrs)) {
                animateTransform.setAttribute(key, value);
              }
              child.node.appendChild(animateTransform);
            }
          }
          break;
        case ']':
          [currBranch, currPoints] = stack.pop();
          break;
        default:
          break;
      }
    }

    // Rescale to fit in container
    bbox.height = bbox.ymax - bbox.ymin;
    bbox.width = bbox.xmax - bbox.xmin;
    bbox.center = [
      (bbox.xmax - bbox.xmin) / 2,
      (bbox.ymax - bbox.ymin) / 2
    ]
    // // Translate so top left of bbox aligns with top left of container
    base.attr({
      transform: `translate(${-bbox.xmin}, ${-bbox.ymin})`
    });

    // Scale until either height <= container_height and width <= container_width
    // If longer and thinner than container...
    let scaleFactor = 1;
    if (bbox.height / bbox.width > height / width) {
      // Scale the height to be slightly smaller than container height
      scaleFactor = 0.9*height / bbox.height;
      base.attr({
        transform: `scale(${scaleFactor}) ${base.attr('transform')}`
      });
      bbox.newHeight = scaleFactor * bbox.height;
      bbox.newWidth = scaleFactor * bbox.width;
    }
    // If shorter and wider than container...
    else {
      // Scale the width to be slightly smaller than container width
      scaleFactor = 0.9*width / bbox.width;
      base.attr({
        transform: `scale(${scaleFactor}) ${base.attr('transform')}`
      });
      bbox.newHeight = scaleFactor * bbox.height;
      bbox.newWidth = scaleFactor * bbox.width;
    }

    // Correct stroke width of all elements
    base.each(function(i, children) {
      if (this.type === 'polyline') {
        this.attr({ 'stroke-width': this.attr('stroke-width') * (1 / scaleFactor) })
      }
    }, true)

    // Translate to center in bbox
    base.attr({
      transform: `translate(${(width - bbox.newWidth) / 2}, ${(height - bbox.newHeight) / 2}) ${base.attr('transform')}`
    });

    // Return the maximum iteration count
    return this.previewMaxIters;
  }

  /**
   * Converts this path into a plain object.
   * @return {object} object containing keys:
   *
   */
  serialize(includeCoords = true) {
    let coordscopy = _.cloneDeep(this.coords);
    let paramscopy = _.cloneDeep(this.params);
    let loggingcopy = _.cloneDeep(this.logging);
    if (includeCoords) {
      return {
        coords: coordscopy,
        layerID: this.layer.node.id,
        params: paramscopy,
        logging: loggingcopy,
      };
    } else {
      return {
        layerID: this.layer.node.id,
        params: paramscopy,
        logging: loggingcopy,
      };
    }
  }

  /**
   *
   * @param {object} serializedPath
   * @param {object} draw
   * @return {Path} the deserialized path
   */
  static deserialize(serializedPath, draw, primarySketch) {
    let layer = document.getElementById(serializedPath.layerID).instance;
    serializedPath.params.isImported = false;
    const newCopy = new Lsys2(
      draw,
      serializedPath.coords,
      layer,
      serializedPath.params,
      serializedPath.logging
    ); // TODO: make this constructor call correct
    if (serializedPath?.params.stops?.length > 0)
      newCopy.setGradientStops(serializedPath.params.stops, primarySketch);
    if (serializedPath?.params?.potraced === true)
      newCopy.svgPath.attr("potraced", "yes");
    return newCopy;
  }

  calculatePathCoords() {
    this.pathCoords = [];
    const zoomLevel = this.draw.viewbox().zoom;
    const precisionNeeded = Math.log10(zoomLevel) | (0 + 2);
    const toleranceNeeded = zoomLevel < 0 ? 1 : 0.1 ** Math.log10(zoomLevel);
    const loops = pathDataToPolys(this.params.pathRep, {
      tolerance: toleranceNeeded,
      decimals: precisionNeeded,
    });

    /*const dx = this.params.dx || 0, dy = this.params.dy || 0;
    const oldSGTransformX = this.params.oldSGTransformX || 0, oldSGTransformY = this.params.oldSGTransformX || 0;
    const scale = this.params.scale || 1;*/

    for (let j = 0; j < loops.length; j++) {
      let loopCoords = [];
      for (let i = 0; i < loops[j].length; i++) {
        loopCoords.push(loops[j][i][0]); // no dx - oldSGTransformX needed ??
        loopCoords.push(loops[j][i][1]);
      }
      this.pathCoords.push(loopCoords);
    }
  }

  calculateBasePolyBoundary() {
    this.basePolyBoundary = [];
    // Modified from Path.setPoints
    // Operates on 2D points
    const basePoly = this.svgPath.select('polyline').first();
    const points = basePoly.array().value;
    // Calculates a 2D bounding polygon around the polyline
    // algorithm only works with 3+ points
    if(points.length < 3) return [];

    // if starting points are all the same point then return
    let startingIndex = -1;
    for (let i = 0; i < points.length - 1; i++) {
      if (getVecLen(getVec(points[i], points[i + 1])) > 1e-2) {
        startingIndex = i;
        break;
      }
    }
    if (startingIndex === -1) return [];

    // arrays to hold points on the clockwise and counterclockwise "side" of the stroke
    let pointsCCW = [];
    let pointsCW = [];
    
    // calculate bvalues for the first point
    let startingDirection = getVec(points[startingIndex], points[startingIndex + 1]);
    let startingOrthoLength = basePoly.attr('stroke-width');
    let [startingOrthoCCW, startingOrthoCW] = calcOrthos(startingDirection, startingOrthoLength);
    let startingCCWPoint = addVec(points[startingIndex], startingOrthoCCW);
    let startingCWPoint = addVec(points[startingIndex], startingOrthoCW);
    let startingTailedCCWPoint = addVec(points[startingIndex + 1], startingOrthoCCW);
    let startingTailedCWPoint = addVec(points[startingIndex + 1], startingOrthoCW);
    pointsCCW.push(startingCCWPoint);
    pointsCW.push(startingCWPoint);

    // calculate middle points
    // assume normal and tail points use same pressure for ortho length
    // this is not true but for optimization's sake we will assume
    let previousOrthoCCW = startingOrthoCCW;
    let previousOrthoCW = startingOrthoCW;
    let previousTailedCCWPoint = startingTailedCCWPoint;
    let previousTailedCWPoint = startingTailedCWPoint;
    let previousOrthoLength = startingOrthoLength;

    for(let i = startingIndex + 1; i < points.length - 1; i++){
      let currPoint = points[i];
      let nextPoint = points[i + 1];

      // get stroke direction, if not moving then skip duplicate point
      let direction = getVec(currPoint, nextPoint);
      if (getVecLen(direction) < 1e-2) {
        continue;
      }

      // calculate current point orthogonals 
      let orthoLength = basePoly.attr('stroke-width');
      if (!orthoLength) orthoLength = previousOrthoLength; // temp fix for last cursor event missing pressure 
      let [orthoCCW, orthoCW] = calcOrthos(direction, orthoLength);

      // fix injected points causing artifacts
      // if (currPoint[2] === 1 && nextPoint[2] === 0);
      // else if (currPoint[2] === 1 || nextPoint[2] === 1){
      //   orthoCCW = previousOrthoCCW;
      //   orthoCW = previousOrthoCW;
      // }

      // calculate CW and CCW points 
      // let CCWPoint = addVec(currPoint, orthoCCW);
      // let CWPoint = addVec(currPoint, orthoCW);
      let tailedCCWPoint = addVec(nextPoint, orthoCCW);
      let tailedCWPoint = addVec(nextPoint, orthoCW);

      // because direction is so random, need to normalize it
      // TODO use inkscape to investigate but need to turn off roundind
      /*let rawAngle = getAngle(previousDirection, direction);
      previousAngles.push(rawAngle);
      let angle = stabilizerName === 'spring' ? rawAngle : movingAverage(previousAngles, 10);*/

      // if the turn is not sharp, find average between current and previous orthogonal and use it (less noise)
      // otherwise, truncate the end (should insert curved point here) 
      let averageOrthoCCW = scaleVec(normVec(addVec(previousOrthoCCW, orthoCCW)), orthoLength);
      let averageOrthoCW = scaleVec(normVec(addVec(previousOrthoCW, orthoCW)), orthoLength);

      // strange bug where current CCW ortho cancels out with previous CCW ortho, which seems like a CW ortho?
      // maybe there's an edge case mistake somewhere but for now, use isNaN to ignore the case
      if(
        isNaN(averageOrthoCCW[0]) || isNaN(averageOrthoCCW[1]) || 
        isNaN(averageOrthoCW[0]) || isNaN(averageOrthoCW[1])
      ){
        // console.log(previousOrthoCCW, orthoCCW, orthoLength);
      }

      else {
        pointsCCW.push(addVec(currPoint, averageOrthoCCW));
        pointsCW.push(addVec(currPoint, averageOrthoCW));
      }

      // cache previous point calculations
      previousOrthoCCW = orthoCCW;
      previousOrthoCW = orthoCW;
      previousTailedCCWPoint = tailedCCWPoint;
      previousTailedCWPoint = tailedCWPoint;
      previousOrthoLength = orthoLength;
    }

    // calculate final points
    pointsCCW.push(previousTailedCCWPoint);
    pointsCW.push(previousTailedCWPoint);

    // generate start cap
    let startCapDirec = getVec(points[startingIndex], points[startingIndex + 1]);
    let startCapPoints = [];
    let vecLen = getVecLen(startCapDirec);
    let scale = startingOrthoLength / vecLen;
    for (let theta = -Math.PI; theta <= Math.PI; theta += Math.PI / 6) {
      let x1 = startCapDirec[0] * scale;
      let y1 = startCapDirec[1] * scale;
      let x2 = Math.cos(theta) * x1 - Math.sin(theta) * y1;
      let y2 = Math.sin(theta) * x1 + Math.cos(theta) * y1;
      startCapPoints.push(addVec(points[startingIndex], [x2, y2]));
    }

    // generate end cap
    let lastIndex = -1;
    for(let i = points.length - 1; i > 0; i--){
      if(getVecLen(getVec(points[i], points[i - 1])) > 1e-2){
        lastIndex = i;
        break;
      }
    }
    if(lastIndex === -1) return;

    let endCapDirec = getVec(points[lastIndex - 1], points[lastIndex]);
    let endCapPoints = [];
    for (let theta = -Math.PI; theta <= Math.PI; theta += Math.PI / 6) {
      let vecLen = getVecLen(endCapDirec);
      let width = previousOrthoLength;
      let scale = width / vecLen;
      let x1 = endCapDirec[0] * scale;
      let y1 = endCapDirec[1] * scale;
      let x2 = Math.cos(theta) * x1 - Math.sin(theta) * y1;
      let y2 = Math.sin(theta) * x1 + Math.cos(theta) * y1;
      endCapPoints.push(addVec(points[lastIndex], [x2, y2]));
    }

    let finalPoints = [
      ...pointsCW,
      ...pointsCCW.reverse(),
    ];

    finalPoints.push(finalPoints[0]);

    // Transform final points according to the base stroke translation
    let transfX = this.svgPath.transform().transformedX;
    let transfY = this.svgPath.transform().transformedY;

    finalPoints = finalPoints.map(p => [p[0] + transfX, p[1] + transfY]);

    this.basePolyBoundary = finalPoints;
  }

  transformToBranch(segment, branch, lengthScale) {
    // Calculate absolute angle of segment
    const segX = segment.endPoint[0] - segment.startPoint[0];
    const segY = segment.endPoint[1] - segment.startPoint[1];
    let alpha = Math.atan2(segX, -segY);

    // Calculate segment length, scale by length factor
    let segLen = getVecLen(getVec(segment.startPoint, segment.endPoint));
    segLen = segLen * lengthScale;

    // Segment start point: last point of the branch
    const segStart = branch.points[branch.points.length - 1];

    // Segment end point: add segLen in the direction of alpha + heading
    const displacement = rotVec([0, segLen], alpha - Math.PI + branch.heading);
    const segEnd = addVec(segStart, displacement);

    return {
      startPoint: segStart,
      endPoint: segEnd,
    };
  }

  waitForAnimations() {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        let allAnimationsDone = true;
  
        this.svgPath.each(function(i, children) {
          if (this.fx && this.fx.active) {
            allAnimationsDone = false;
          }
        }, true);
  
        if (allAnimationsDone) {
          clearInterval(interval);
          resolve();
        }
      }, 1000); // Check every second
    });
  }

  /**
   * @param {points} points
   */
  setPoints(points, stabilizerName) {
    // Get origin point
    if (this.originPoint === undefined && points.length > 0) {
      this.originPoint = points[0].slice(0, 2);
    }
    // Get most recent point, transform to "object space" (subtract origin)
    let newestPoint;
    if (points.length > 0) {
      newestPoint = points[points.length - 1].slice(0, 2);
      // newestPoint = addVec(newestPoint, this.originPoint.map(a => -a));

      // // Also, if this.svgPath doesn't have a translate yet, give it one
      // if (!this.svgPath.attr('transform').includes('translate')) {
      //   this.svgPath.attr({
      //     transform: `translate(${this.originPoint[0]}, ${this.originPoint[1]})`
      //   })
      // }
    }
    // If there is one active branch and it's missing a point, add 0,0
    if (
      this.activeBranches.length === 1 &&
      this.activeBranches[0].points.length === 0
    ) {
      this.activeBranches[0].points.push(this.originPoint);
    }

    // Check if we've moved far enough to add a segment
    let segStart = this.originPoint; // object space origin
    if (this.segments.length > 0) {
      segStart = this.segments[this.segments.length - 1].endPoint;
    }
    let segEnd = newestPoint;
    // If we have moved far enough...
    if (getVecLen(getVec(segStart, segEnd)) > this.lsysConf.segmentLength) {
      // Add a segment
      let newSegment = {
        startPoint: segStart,
        endPoint: segEnd,
      };
      this.segments.push(newSegment);
      // console.log("NEW SEGMENT###################");

      // Create a stack of branches to process
      let branchesToProcess = [];
      for (const branch of this.activeBranches) {
        branchesToProcess.push(branch);
      }

      // While we still have branches to process...
      label: while (branchesToProcess.length > 0) {
        const branch = branchesToProcess.pop();

        // Log for debugging
        // console.log(`Processing branch: ${branch.id}`);

        // Iterate through L-system rules until we find an F
        let module = this.lstring[branch.lstringPtr];

        while (module !== undefined && module.symbol !== "F" && module.symbol !== "G" && module.symbol !== "H" && module.symol !== "I") {
          // // Log for debugging
          // let lstringBranch = this.lstring.slice(
          //   branch.lstringPtr,
          //   branch.lstringPtr + 10
          // );
          // console.log(`next part of lstring: ${lmodulesToString(lstringBranch)}`);
          const symbol = module.symbol;
          const param = module.param;

          switch (symbol) {
            case "l": // Turn CCW
            case "+":
              if (branch.id !== "base")
                if (this.orientationFlipped) {
                  branch.heading = branch.heading + param;
                }
                else {
                  branch.heading = branch.heading - param;
                }
              break;
            case "r": // Turn CW
            case "-":
              if (branch.id !== "base")
                if (this.orientationFlipped) {
                  branch.heading = branch.heading - param;
                }
                else {
                  branch.heading = branch.heading + param;
                }
                break;
            case "[": // Add branch
              // Create a new child svg element
              const childId =
                branch.id + "_c" + Math.floor(Math.random() * 1000);
              // Use the newest point of the parent branch as the first point of the child
              const parentNewestPoint = branch.points[branch.points.length - 1];
              let children = branch.svg.select("g").first();
              let child = children.group().attr({
                id: childId,
                transform: `translate(${parentNewestPoint[0]}, ${parentNewestPoint[1]})`
              });
              // Get parent polyline stroke width
              let parentPolyline = branch.svg.select("polyline").first();
              let parentWidth = parentPolyline.attr("stroke-width");
              child.polyline().fill("none")
                .stroke({
                  ...this.options,
                  width: this.lsysConf.branchThicknessScale * parentWidth,
                });
              child.group();

              if (this.lsysConf.animated) {
                // Used to set animation phase properly
                let timeSinceStrokeStart = (Date.now() - this.startTime) / 1000;

                // SWAY ANIMATION
                let swayAngle = this.animationConf.swayAngleAbs;
                if (this.orientationFlipped) {
                  swayAngle = -this.animationConf.swayAngleAbs;
                }
                let swayBegin = (timeSinceStrokeStart % this.animationConf.swayPeriod) + "s"
                if (this.animationConf.swaySynchronized) {
                  swayBegin = "0s";
                }
                let swayAttrs = this.createSwayAttrs(
                  this.animationConf.swayPeriod,
                  swayAngle,
                  swayBegin,
                  this.animationConf.swaySmooth
                );

                // BOUNCE ANIMATION
                let bounceBegin = (timeSinceStrokeStart % this.animationConf.bouncePeriod) + "s"
                if (this.animationConf.bounceSynchronized) {
                  bounceBegin = "0s";
                }
                let bounceAttrs = this.createBounceAttrs(
                  this.animationConf.bouncePeriod,
                  this.animationConf.bounceAmplitudeXMin,
                  this.animationConf.bounceAmplitudeXMax,
                  this.animationConf.bounceAmplitudeYMin,
                  this.animationConf.bounceAmplitudeYMax,
                  bounceBegin,
                  this.animationConf.bounceSmooth
                );

                // ADD ANIMATIONS
                if (this.animationConf.bounceOn) {
                  child.element('animateTransform').attr(bounceAttrs);
                }
                if (this.animationConf.swayOn) {
                  child.element('animateTransform').attr(swayAttrs);
                }
              }

              if (!this.lsysConf.animated || (!this.animationConf.bounceOn && !this.animationConf.swayOn)) {
                // ADD EPHEMERAL SCALE ANIMATION:
                child.scale(0.05);
                child.animate(300, '>', 0).scale(1);
              }

              // Child's lstringPtr should be parent's lstringPtr + 1
              const childLstringPtr = branch.lstringPtr + 1;

              // Parent's lstringPtr should jump to the closing ]
              let bracketCounter = 1;
              while (bracketCounter > 0 && branch.lstringPtr < this.lstring.length) {
                branch.lstringPtr++;
                if (this.lstring[branch.lstringPtr].symbol === "]") {
                  bracketCounter--;
                } else if (this.lstring[branch.lstringPtr].symbol === "[") {
                  bracketCounter++;
                }
              }

              // If no closing bracket was found...
              if (branch.lstringPtr === this.lstring.length) {
                console.log("Error, unmatched bracket");
                return;
              }

              this.activeBranches.push({
                svg: child,
                heading: branch.heading,
                points: [[0, 0]],
                lstringPtr: childLstringPtr,
                lengthFactor: branch.lengthFactor * this.lsysConf.branchLengthScale,
                id: childId,
              });
              // Add new branch to the processing stack
              branchesToProcess.push(
                this.activeBranches[this.activeBranches.length - 1]
              );
              break;
            case "]":
              // Find branch in this.activeBranches and remove it
              const indexOfBranch = this.activeBranches.findIndex( // TODO: potentially slow
                (br) => br.id === branch.id
              );
              if (indexOfBranch < 0) {
                console.log("ERROR");
              }
              this.activeBranches.splice(indexOfBranch, 1);
              continue label;
            case "@":
              // Draw a circle
              let currentPoint = branch.points[branch.points.length - 1];
              let circle = this.draw
                .circle(7)
                .fill("#FFD1DC")
                .move(currentPoint[0], currentPoint[1]);
              branch.svg.add(circle);
              break;
            default:
              break;
          }
          branch.lstringPtr++;
          module = this.lstring[branch.lstringPtr];
        }
        let lengthParam = 1;
        if (module === undefined) {
          console.log(
            `Got to undefined symbol! branch ${branch.id} reached the end of its string! (should only ever happen for base stroke)`
          );
        }
        else {
          lengthParam = module.param;
        }
        // Once we get to the F or G, give the branch its transformed
        //  version of the newly added segment
        let newSegmentTransformed = this.transformToBranch(newSegment, branch, lengthParam);
        branch.points.push(newSegmentTransformed.endPoint);
        // Update the SVG representation with the new points
        let polyline = branch.svg.select("polyline").first();
        polyline.plot(branch.points);

        // console.log(`Finished adding new segment to branch ${branch.id}`);

        branch.lstringPtr++;
      }
    }
    // DRAW
    // let baseStrokePoints = [];
    // if (this.segments.length > 0) {
    //   baseStrokePoints = this.segments.map((seg) => seg.startPoint);
    //   baseStrokePoints.push(this.segments[this.segments.length - 1].endPoint);
    // }
    // // Draw base stroke + partial segment at the end
    // this.coords = baseStrokePoints.concat([segStart, segEnd]);
    // let polyline = this.svgPath.select("polyline").first();
    // polyline.plot(this.coords);
  }

  /**
   * Moves the path by a certain displacement.
   *
   * @param {number} x
   * @param {number} y
   */
  moveBy(x, y, primarySketch) {
    if (!this.params.potraced) {
      let numSigFigs = Math.max(
        getNumSigFigs(this.coords[0]),
        getNumSigFigs(this.coords[1])
      );
      let coordScaleFactor = Math.pow(10, numSigFigs);

      for (let i = 0; i < this.coords.length; i++) {
        this.coords[i] = i % 2 === 0 ? this.coords[i] + x : this.coords[i] + y;
        this.coords[i] =
          Math.round(this.coords[i] * coordScaleFactor) / coordScaleFactor;
      }

      this.svgPath.plot(this.coords);
    } else {
      const pathData = this.svgPath.node.getPathData();
      for (let command of pathData) {
        for (let i = 0; i < command.values.length; i += 2) {
          command.values[i] += x;
          command.values[i + 1] += y;
        }
      }
      for (let command of pathData) {
        command.values = command.values.map((n) => Math.round(n * 100) / 100);
      }

      this.svgPath.node.setPathData(pathData);
      this.params.pathRep = this.svgPath.node.getAttribute("d");
    }

    if (this.params.stops.length > 0) {
      this.setGradientStops(this.params.stops, primarySketch);
      this.gradientCollection.opacity(0.1);
    }
  }

  /**
   * "Highlights" the path by changing its opacity.
   */
  highlight(newOpacity = null) {
    if (newOpacity !== null) {
      this.svgPath.opacity(newOpacity);
      this.gradientCollection?.opacity(newOpacity);
    } else {
      this.svgPath.opacity(this.params.opacity);
      this.gradientCollection?.opacity(this.params.opacity);
    }
  }

  /**
   * Changes the color of the path
   * @param {string} color
   */
  setColor(color) {
    this.params.color = color;
    // this.svgPath.stroke({color: color})
    if (/* this.params.fill*/ true) {
      this.svgPath.fill(this.params.color);
    }
  }

  changeFilter(newFilterID) {
    this.params.filterID = newFilterID;
    if (this.params.filterID === "empty") {
      this.svgPath.attr("filter", "");
    } else {
      this.svgPath.attr("filter", "url(#" + newFilterID + ")");
    }
  }

  updateFilterVisibility(filterID, isVisible) {
    this.params.filterIsVisible = isVisible;

    if (this.params.filterID === filterID) {
      if (isVisible) {
        this.svgPath.attr("filter", "url(#" + this.params.filterID + ")");
      } else {
        this.svgPath.attr(
          "filter",
          "url(#" + this.params.filterID + "___hidden)"
        );
      }
    }
  }

  /**
   * Stop rendering this path on the SVG.
   */
  remove(status) {
    if (this.params.filterID) {
      this.filterElement = document.getElementById(this.params.filterID);
    }
    this.svgPath.remove();
    this.logging.rendered = false;
    this.logging.status = status;
    if (status === 2) {
      this.logging.erased = true;
    }

    if (this.params.stops?.length > 0) {
      this.gradientCollection.remove();
    }
  }

  /**
   * Adds this path to the group so it can be rendered.
   *
   * @param {object} sketchGroup
   */
  addToGroup(sketchGroup) {
    let filterElementExists = document.getElementById(this.params.filterID);
    if (!filterElementExists && this.filterElement) {
      let svg = document.getElementById("main-canvas");
      svg.appendChild(this.filterElement);
    }
    sketchGroup.add(this.svgPath);
    // sketchGroup.add(this.svgGuide)
    this.logging.rendered = true;
    this.logging.status = 1;
    this.highlight();

    if (this.gradientCollection) this.layer.add(this.gradientCollection);
  }

  pathCoordsAtIndex(coords, index, xy) {
    return coords[index * 2 + xy];
  }

  smoothCoords(coords) {
    let str = "";
    str +=
      "M " +
      this.pathCoordsAtIndex(coords, 0, 0) +
      " " +
      this.pathCoordsAtIndex(coords, 0, 1) +
      " ";
    let skip1 = true;
    let skip2 = false;
    let cp1x, cp1y, cp2x, cp2y;
    for (let i = 0; i < coords.length / 2 - 1; i++) {
      if (skip1) {
        cp1x = this.pathCoordsAtIndex(coords, i, 0); // x
        cp1y = this.pathCoordsAtIndex(coords, i, 1); // y
        skip1 = false;
        skip2 = true;
      }
      if (skip2) {
        cp2x = this.pathCoordsAtIndex(coords, i, 0); // x
        cp2y = this.pathCoordsAtIndex(coords, i, 1); // y

        skip1 = false;
        skip2 = false;
      } else {
        str +=
          "C " +
          cp1x +
          " " +
          cp1y +
          " " +
          cp2x +
          " " +
          cp2y +
          " " +
          this.pathCoordsAtIndex(coords, i, 0) +
          " " +
          this.pathCoordsAtIndex(coords, i, 1) +
          " ";
        skip1 = true;
        skip2 = false;
      }
    }
    return str;
  }

  addToGroupSmoothed(sketchGroup) {
    this.svgPath.remove();
    let path = this.params.fill
      ? this.draw
          .path(this.smoothCoords(this.coords))
          .fill({ color: this.params.color, opacity: this.params.opacity })
      : this.draw
          .path(this.smoothCoords(this.coords))
          .fill("none")
          .stroke(this.options);
    this.svgPath = path;
    sketchGroup.add(path);
  }

  trimCoords() {
    let points = [];
    let viewbox = this.draw.viewbox();
    for (let i = 0; i < this.coords.length - 1; i += 2) {
      points.push({
        x: this.coords[i] * (1 / this.params.filterResCorrection),
        y: this.coords[i + 1] * (1 / this.params.filterResCorrection),
      });
    }
    let simplified = simplify(points, 0.5 / viewbox.zoom);
    let coordScaleFactorX = Math.pow(
      10,
      getCoordScaleFactor(viewbox.width * this.params.filterResCorrection)
    );
    let coordScaleFactorY = Math.pow(
      10,
      getCoordScaleFactor(viewbox.height * this.params.filterResCorrection)
    );
    let newPoints = [];
    for (let i = 0; i < simplified.length; i++) {
      newPoints.push(
        Math.round(
          simplified[i].x * this.params.filterResCorrection * coordScaleFactorX
        ) / coordScaleFactorX
      );
      newPoints.push(
        Math.round(
          simplified[i].y * this.params.filterResCorrection * coordScaleFactorY
        ) / coordScaleFactorY
      );
    }
    this.coords = newPoints;
    this.svgPath.plot(this.coords);
  }
  
  setGradientStops(stops, primarySketch) {
    this.params.stops = stops;
    this.orderIndex = -1; // if gradient already exists, it will have a certain index in the entire sketch's <g> and we want to preserve the order!

    if (this.gradientCollection && this.gradientCollection.remove) {
      let layerID = this.layer.node.id;
      const allChildren = document.getElementById(layerID).children;
      const targetId = this.svgPath.node.id;

      for (let i = 0; i < allChildren.length; i++) {
        if (targetId === allChildren[i].id) this.orderIndex = i + 1;
      }

      this.gradientCollection.remove();
    }

    const gradCollection = this.draw.group();
    gradCollection.attr("pointer-events", "none");
    gradCollection.attr("gradient-overlay", this.svgPath.id());

    this.svgPath.attr("gradient-ids", stops.map((s) => s.id).join(","));

    if (this.filter !== null && this.filter !== "") {
      gradCollection.attr("filter", this.filter);
    }

    for (let i = 0; i < stops.length; i++) {
      const curStop = stops[i];
      const pathNode = this.svgPath.clone();
      pathNode.fill(`url(#${curStop.id})`);
      pathNode.attr(`opacity`, "1");
      gradCollection.add(pathNode);
    }

    if (this.orderIndex === -1) this.layer.add(gradCollection);
    else this.layer.add(gradCollection, this.orderIndex);

    this.gradientCollection = gradCollection;
  }
}
