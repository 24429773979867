import React, {Component} from "react";
import DisplayList from "./displaylist";
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as AddIcon } from '../assets/add.svg'

import "./inkpage.css"
import { FormControlLabel, Switch } from "@material-ui/core";

export class InkPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeholder: 1
        }
    }

    render() {
        return (
            <div id="inkpage">
                <div id="ink-topbar">
                    <div id="ink-outer-container">
                        <div id="ink-inner-container">
                            <Tooltip title="Flip Orientation">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={this.props.L_inkOrientationFlipped}
                                            onChange={() => {
                                                this.props.flipL_inkOrientation()
                                            }}/>
                                    }
                                    labelPlacement="start"
                                    label={<span className='small-bold-text'>Flip L-ink Orientation</span>}
                                />
                            </Tooltip>
                            <Tooltip title="Add ink">
                                <IconButton
                                    id="add-ink-btn"
                                    onClick={() => this.props.addL_inkSet()}
                                    color="secondary"
                                    aria-label="add ink"
                                    size="small">
                                        <SvgIcon>
                                            <AddIcon/>
                                        </SvgIcon>
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <DisplayList
                    list={this.props.list}
                    changeSelectedL_ink={this.props.changeSelectedL_ink}
                    editDialogOpenIndex={this.props.editDialogOpenIndex}
                    openEditDialogAtIndex={this.props.openEditDialogAtIndex}
                    removeL_ink={this.props.removeL_ink}
                    handleLsysConfChange={this.props.handleLsysConfChange}
                    handleLsysRuleChange={this.props.handleLsysRuleChange}
                    handleLsysRuleModuleChange={this.props.handleLsysRuleModuleChange}
                    handleLsysAddModules={this.props.handleLsysAddModules}
                    handleLsysRemoveModules={this.props.handleLsysRemoveModules}
                    handleLsysConfReset={this.props.handleLsysConfReset}
                    changeL_inkID={this.props.changeL_inkID}
                    addL_inkSet={this.props.addL_inkSet}
                    setLstring={this.props.setLstring}
                    L_inkLengthLimit={this.props.L_inkLengthLimit}
                    L_inkStagnationLimit={this.props.L_inkStagnationLimit}
                    L_inkIterLimit={this.props.L_inkIterLimit}
                    L_inkOrientationFlipped={this.props.L_inkOrientationFlipped}/>
            </div>
        )
    }
}