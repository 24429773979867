import React from 'react'
import { Card } from '@material-ui/core'
import './colorpalette.css';
import './waypoints.css'
import PinDropIcon from '@material-ui/icons/PinDrop';
import { convertSVGToPreview } from '../util';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';

export class Waypoints extends React.Component {
    constructor(props){
        super(props);

        this.width = 3;
        this.height = 10;

        this.state = {
            waypoints: []
        };

        this.dragging = false;
        this.cachedOffset = [0, 0];
        this.previousOffset = [0, 0];

        document.addEventListener('mousemove', (e) => {
            if(!this.dragging) return;
            const dialog = document.querySelector('.waypoint-card');
            const ds = dialog.style;
            const nx = e.clientX;
            const ny = e.clientY;

            const px = `${(ny - this.cachedOffset[1]) + this.previousOffset[1]}px`;
            const py = `${nx - this.cachedOffset[0] + this.previousOffset[0]}px`;
            ds.translate = `${py} ${px}`;
        });
        document.addEventListener('mouseup', () => {
            if(this.dragging) this.dragging = false;
        });
    }

    returnWaypoints() {
        return this.state.waypoints
    }

    handleWaypointClick(waypoint) {
        if (this.props.parallaxOn === waypoint.parallaxIsOn) {
            if (this.props.parallaxOn) {
                this.props.setParallax(waypoint.depth, waypoint.posX, waypoint.posY)
            }
            else {
                this.props.setViewBox(waypoint.viewboxString)
            }
        }
    }

    clearWaypoints(callback) {
        this.setState({waypoints: []}, () => {
            if (callback) {
                callback()
            }
        })
    }

    addWaypointFromImport(waypoint) {
        this.setState(prevState => ({
            waypoints: [...prevState.waypoints, waypoint]
        }))
    }

    addWaypoint() {
        let svgString = this.props.serializeToString(true, false)
        let previewURL = convertSVGToPreview(svgString)

        let currentWaypoints = [...this.state.waypoints]
        if (this.props.parallaxOn) {
            currentWaypoints.push({url: previewURL, parallaxIsOn: this.props.parallaxOn, depth: this.props.depth, posX: this.props.position[0], posY: this.props.position[1]})
        }
        else {
            const viewbox = this.props.draw.viewbox()
            const viewboxString = `${viewbox.x} ${viewbox.y} ${viewbox.width} ${viewbox.height}`
            currentWaypoints.push({url: previewURL, parallaxIsOn: this.props.parallaxOn, viewboxString: viewboxString})
        }
        this.setState({ waypoints: currentWaypoints }, () => {});
    }

    deleteWaypoint(e, index) {
        e.stopPropagation()
        let currentWaypoints = [...this.state.waypoints]
        currentWaypoints.splice(index, 1);
        this.setState({ waypoints: currentWaypoints });
    }

    render(){
        return (<Card className='waypoint-card' style={this.props.waypointDialogOpen ? {display: "block"} : {display: "none"}}>
            <div 
                className='palette-handle'
                onPointerDown={(e) => {
                    this.dragging = true;
                    this.cachedOffset = [
                        e.clientX,
                        e.clientY
                    ];

                    const dialog = document.querySelector('.waypoint-card');
                    const ds = dialog.style;
                    this.previousOffset = ds.translate.split(' ').map(s => parseFloat(s.slice(0, -2)));
                    if(isNaN(this.previousOffset[0])) this.previousOffset = [0, 0];
                }}>
                <div className='palette-handle-line'></div>
                <div className='palette-handle-line'></div>
            </div>
            <div className='waypoint-rows-container' onContextMenu={(e) => e.preventDefault()}>
                <div style={{textAlign: "center", color: "gray"}}><PinDropIcon size="small"/></div>
                <Tooltip title='Add waypoint'>
                    <div className="waypoint-preview-empty" 
                        onClick={() => this.addWaypoint()}>
                        +
                    </div>
                </Tooltip>
                <div className='pad-top-small'></div>
                <div className='waypoint-list'>
                    {this.state.waypoints.map((waypoint, index) => {
                        return(
                            <div key={`waypoint-${index}`} className='layers-preview-wrapper'>
                                <div className='waypoint-preview-wrapper' onClick={(index) => this.handleWaypointClick(waypoint)}>
                                    <img id={`waypoint-thumbnail-${index}`} alt={`preview for waypoint with index ${index}`} src={waypoint.url} className={this.props.parallaxOn === waypoint.parallaxIsOn ? 'waypoint-preview' : 'waypoint-preview-disabled'}/>
                                    <Tooltip title='Delete waypoint'>
                                        <ClearIcon size='small' className={this.props.parallaxOn === waypoint.parallaxIsOn ? 'close-button' : 'close-button-disabled'} onClick={(e) => this.deleteWaypoint(e, index)}/>
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Card>);
    }
}